import { LocationService } from '../services/location.service';
import { ApiService } from '../services/api.service';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { requireDefined } from 'yti-common-ui/utils/object';
import { index } from 'yti-common-ui/utils/array';
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'yti-common-ui/services/user.service';
import { AuthorizationManager } from '../services/authorization-manager.service';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { DeleteConfirmationModalService } from './delete-confirmation-modal.component';
import { map } from 'rxjs/operators';
var UsersComponent = /** @class */ (function () {
    function UsersComponent(apiService, locationService, languageService, translateService, userService, authorizationManager, deleteUserModal) {
        var _this = this;
        this.apiService = apiService;
        this.locationService = locationService;
        this.authorizationManager = authorizationManager;
        this.deleteUserModal = deleteUserModal;
        this.search$ = new BehaviorSubject('');
        this.role$ = new BehaviorSubject(null);
        this.organization$ = new BehaviorSubject(null);
        this.usersForOwnOrganizations = new Subject();
        this.refreshUsers();
        this.apiService.getAllRoles().subscribe(function (roles) {
            _this.roleOptions = [null].concat(roles).map(function (role) { return ({
                value: role,
                name: function () { return translateService.instant(role ? role : 'All roles'); }
            }); });
        });
        var organizations$ = this.apiService.getOrganizationListWithChildren();
        organizations$.subscribe(function (organizations) {
            var ownOrganizations = organizations.filter(function (org) {
                var user = userService.user;
                return user.superuser || user.isInRole('ADMIN', org.id);
            });
            _this.organizationOptions = [null].concat(ownOrganizations).map(function (org) { return ({
                value: org,
                name: function () { return org ? languageService.translate(org.name) : translateService.instant('All organizations'); }
            }); });
        });
        this.users$ = combineLatest(organizations$, this.usersForOwnOrganizations, this.search$, this.role$, this.organization$)
            .pipe(map(function (_a) {
            var organizations = _a[0], users = _a[1], search = _a[2], role = _a[3], organization = _a[4];
            var roleMatches = function (user) {
                return !role || user.organizations.find(function (org) { return org.roles.indexOf(role) !== -1; });
            };
            var organizationMatches = function (user) {
                return !organization || user.organizations.find(function (org) { return org.id === organization.id; }) != null;
            };
            var searchMatchesName = function (user) {
                return !search || user.displayName.toLowerCase().indexOf(search.toLowerCase()) !== -1;
            };
            var searchMatchesEmail = function (user) {
                return !search || user.email.toLowerCase().indexOf(search.toLowerCase()) !== -1;
            };
            var searchMatches = function (user) {
                return searchMatchesName(user) || searchMatchesEmail(user);
            };
            return users.map(function (user) { return new UserViewModel(user, index(organizations, function (org) { return org.id; })); })
                .filter(function (user) { return roleMatches(user) && organizationMatches(user) && searchMatches(user); });
        }));
    }
    UsersComponent.prototype.refreshUsers = function () {
        var _this = this;
        this.apiService.getUsersForOwnOrganizations()
            .subscribe(function (users) { return _this.usersForOwnOrganizations.next(users); });
    };
    Object.defineProperty(UsersComponent.prototype, "loading", {
        get: function () {
            return this.roleOptions == null || this.organizationOptions == null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UsersComponent.prototype, "organization", {
        get: function () {
            return this.organization$.getValue();
        },
        set: function (value) {
            this.organization$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UsersComponent.prototype, "search", {
        get: function () {
            return this.search$.getValue();
        },
        set: function (value) {
            this.search$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    UsersComponent.prototype.canRemoveUser = function () {
        return this.authorizationManager.canRemoveUser();
    };
    UsersComponent.prototype.removeUser = function (user) {
        var _this = this;
        this.deleteUserModal.open(user.displayName, user.email, 'This user will be removed.')
            .then(function () {
            _this.apiService.removeUser(user.email)
                .subscribe(function () { return _this.refreshUsers(); });
        }, ignoreModalClose);
    };
    return UsersComponent;
}());
export { UsersComponent };
var UserViewModel = /** @class */ (function () {
    function UserViewModel(user, organizations) {
        this.user = user;
        this.organizations = user.organizations.map(function (org) {
            return {
                id: org.id,
                name: requireDefined(organizations.get(org.id)).name,
                roles: org.roles
            };
        });
    }
    Object.defineProperty(UserViewModel.prototype, "email", {
        get: function () {
            return this.user.email;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserViewModel.prototype, "displayName", {
        get: function () {
            return this.user.lastName + ', ' + this.user.firstName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserViewModel.prototype, "superuser", {
        get: function () {
            return this.user.superuser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserViewModel.prototype, "creationDateTime", {
        get: function () {
            return this.user.creationDateTime;
        },
        enumerable: true,
        configurable: true
    });
    return UserViewModel;
}());
