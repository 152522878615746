var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
var ConfigurationModel = /** @class */ (function () {
    function ConfigurationModel() {
    }
    return ConfigurationModel;
}());
export { ConfigurationModel };
var CreateOrganization = /** @class */ (function () {
    function CreateOrganization() {
    }
    return CreateOrganization;
}());
export { CreateOrganization };
var EmailRole = /** @class */ (function () {
    function EmailRole() {
    }
    return EmailRole;
}());
export { EmailRole };
var NewUser = /** @class */ (function () {
    function NewUser() {
    }
    return NewUser;
}());
export { NewUser };
var Organization = /** @class */ (function () {
    function Organization() {
    }
    return Organization;
}());
export { Organization };
var OrganizationListItem = /** @class */ (function () {
    function OrganizationListItem() {
    }
    return OrganizationListItem;
}());
export { OrganizationListItem };
var OrganizationRoles = /** @class */ (function () {
    function OrganizationRoles() {
    }
    return OrganizationRoles;
}());
export { OrganizationRoles };
var OrganizationWithUsers = /** @class */ (function () {
    function OrganizationWithUsers() {
    }
    return OrganizationWithUsers;
}());
export { OrganizationWithUsers };
var PrivateApiTempUserListItem = /** @class */ (function () {
    function PrivateApiTempUserListItem() {
    }
    return PrivateApiTempUserListItem;
}());
export { PrivateApiTempUserListItem };
var PublicApiOrganization = /** @class */ (function () {
    function PublicApiOrganization() {
    }
    return PublicApiOrganization;
}());
export { PublicApiOrganization };
var PublicApiUser = /** @class */ (function () {
    function PublicApiUser() {
    }
    return PublicApiUser;
}());
export { PublicApiUser };
var PublicApiUserListItem = /** @class */ (function () {
    function PublicApiUserListItem() {
    }
    return PublicApiUserListItem;
}());
export { PublicApiUserListItem };
var PublicApiUserOrganization = /** @class */ (function () {
    function PublicApiUserOrganization() {
    }
    return PublicApiUserOrganization;
}());
export { PublicApiUserOrganization };
var PublicApiUserRequest = /** @class */ (function () {
    function PublicApiUserRequest() {
    }
    return PublicApiUserRequest;
}());
export { PublicApiUserRequest };
var TempUser = /** @class */ (function () {
    function TempUser() {
    }
    return TempUser;
}());
export { TempUser };
var TokenModel = /** @class */ (function () {
    function TokenModel() {
    }
    return TokenModel;
}());
export { TokenModel };
var UpdateOrganization = /** @class */ (function () {
    function UpdateOrganization() {
    }
    return UpdateOrganization;
}());
export { UpdateOrganization };
var User = /** @class */ (function () {
    function User() {
    }
    return User;
}());
export { User };
var UserRequestModel = /** @class */ (function () {
    function UserRequestModel() {
    }
    return UserRequestModel;
}());
export { UserRequestModel };
var UserRequestWithOrganization = /** @class */ (function () {
    function UserRequestWithOrganization() {
    }
    return UserRequestWithOrganization;
}());
export { UserRequestWithOrganization };
var UserWithRoles = /** @class */ (function () {
    function UserWithRoles() {
    }
    return UserWithRoles;
}());
export { UserWithRoles };
var UserWithRolesInOrganizations = /** @class */ (function () {
    function UserWithRolesInOrganizations() {
    }
    return UserWithRolesInOrganizations;
}());
export { UserWithRolesInOrganizations };
var VersionInformation = /** @class */ (function () {
    function VersionInformation() {
    }
    return VersionInformation;
}());
export { VersionInformation };
var YtiUser = /** @class */ (function () {
    function YtiUser() {
    }
    return YtiUser;
}());
export { YtiUser };
export function registerDefaultSerializers(config) {
    config.registerIdentitySerializer('LocalDateTime');
    config.registerIdentitySerializer('UUID');
    config.registerIdentitySerializer('Role');
    config.registerClassSerializer('ConfigurationModel', {
        'codeListUrl': 'string',
        'commentsUrl': 'string',
        'dataModelUrl': 'string',
        'dev': 'boolean',
        'env': 'string',
        'fakeLoginAllowed': 'boolean',
        'impersonateAllowed': 'boolean',
        'messagingEnabled': 'boolean',
        'terminologyUrl': 'string'
    });
    config.registerClassSerializer('CreateOrganization', {
        'adminUserEmails': 'string[]',
        'descriptionEn': 'string',
        'descriptionFi': 'string',
        'descriptionSv': 'string',
        'nameEn': 'string',
        'nameFi': 'string',
        'nameSv': 'string',
        'parentId': 'UUID',
        'url': 'string'
    });
    config.registerClassSerializer('EmailRole', {
        'role': 'string',
        'userEmail': 'string'
    });
    config.registerClassSerializer('NewUser', {
        'email': 'string',
        'firstName': 'string',
        'id': 'UUID',
        'lastName': 'string'
    });
    config.registerClassSerializer('Organization', {
        'descriptionEn': 'string',
        'descriptionFi': 'string',
        'descriptionSv': 'string',
        'id': 'UUID',
        'nameEn': 'string',
        'nameFi': 'string',
        'nameSv': 'string',
        'parentId': 'UUID',
        'removed': 'boolean',
        'url': 'string'
    });
    config.registerClassSerializer('OrganizationListItem', {
        'id': 'UUID',
        'name': 'Dictionary<string>'
    });
    config.registerClassSerializer('OrganizationRoles', {
        'id': 'UUID',
        'roles': 'string[]'
    });
    config.registerClassSerializer('OrganizationWithUsers', {
        'availableRoles': 'string[]',
        'childOrganizations': 'OrganizationListItem[]',
        'organization': 'Organization',
        'users': 'UserWithRoles[]'
    });
    config.registerClassSerializer('PrivateApiTempUserListItem', {
        'containerUri': 'string',
        'email': 'string',
        'firstName': 'string',
        'id': 'UUID',
        'lastName': 'string',
        'tokenRole': 'string'
    });
    config.registerClassSerializer('PublicApiOrganization', {
        'description': 'Dictionary<string>',
        'parentId': 'UUID',
        'prefLabel': 'Dictionary<string>',
        'removed': 'boolean',
        'url': 'string',
        'uuid': 'UUID'
    });
    config.registerClassSerializer('PublicApiUser', {
        'containerUri': 'string',
        'creationDateTime': 'LocalDateTime',
        'email': 'string',
        'firstName': 'string',
        'id': 'UUID',
        'lastName': 'string',
        'newlyCreated': 'boolean',
        'organization': 'PublicApiUserOrganization[]',
        'removalDateTime': 'LocalDateTime',
        'superuser': 'boolean',
        'tokenCreatedAt': 'LocalDateTime',
        'tokenInvalidationAt': 'LocalDateTime',
        'tokenRole': 'string'
    });
    config.registerClassSerializer('PublicApiUserListItem', {
        'email': 'string',
        'firstName': 'string',
        'id': 'UUID',
        'lastName': 'string'
    });
    config.registerClassSerializer('PublicApiUserOrganization', {
        'role': 'string[]',
        'uuid': 'UUID'
    });
    config.registerClassSerializer('PublicApiUserRequest', {
        'organizationId': 'UUID',
        'role': 'string[]'
    });
    config.registerClassSerializer('TempUser', {
        'containerUri': 'string',
        'creationDateTime': 'string',
        'email': 'string',
        'firstName': 'string',
        'id': 'UUID',
        'lastName': 'string',
        'tokenCreatedAt': 'string',
        'tokenInvalidationAt': 'string',
        'tokenRole': 'string'
    });
    config.registerClassSerializer('TokenModel', {
        'token': 'string'
    });
    config.registerClassSerializer('UpdateOrganization', {
        'organization': 'Organization',
        'userRoles': 'EmailRole[]'
    });
    config.registerClassSerializer('User', {
        'creationDateTime': 'string',
        'email': 'string',
        'firstName': 'string',
        'id': 'UUID',
        'lastName': 'string',
        'superuser': 'boolean',
        'tokenCreatedAt': 'string',
        'tokenInvalidationAt': 'string'
    });
    config.registerClassSerializer('UserRequestModel', {
        'email': 'string',
        'organizationId': 'UUID',
        'role': 'string'
    });
    config.registerClassSerializer('UserRequestWithOrganization', {
        'email': 'string',
        'firstName': 'string',
        'fullName': 'string',
        'id': 'number',
        'lastName': 'string',
        'organizationId': 'UUID',
        'organizationName': 'Dictionary<string>',
        'role': 'string',
        'sent': 'boolean'
    });
    config.registerClassSerializer('UserWithRoles', {
        'roles': 'string[]',
        'user': 'User'
    });
    config.registerClassSerializer('UserWithRolesInOrganizations', {
        'creationDateTime': 'LocalDateTime',
        'email': 'string',
        'firstName': 'string',
        'lastName': 'string',
        'organizations': 'OrganizationRoles[]',
        'removalDateTime': 'LocalDateTime',
        'superuser': 'boolean',
        'userId': 'UUID'
    });
    config.registerClassSerializer('VersionInformation', {
        'branch': 'string',
        'commitId': 'string'
    });
    config.registerClassSerializer('YtiUser', {
        'accountNonExpired': 'boolean',
        'accountNonLocked': 'boolean',
        'anonymous': 'boolean',
        'authorities': 'any[]',
        'containerUri': 'string',
        'credentialsNonExpired': 'boolean',
        'email': 'string',
        'enabled': 'boolean',
        'firstName': 'string',
        'id': 'UUID',
        'lastName': 'string',
        'newlyCreated': 'boolean',
        'organizationsInRole': 'Dictionary<any>',
        'password': 'string',
        'rolesInOrganizations': 'Dictionary<any>',
        'superuser': 'boolean',
        'tokenCreatedAt': 'LocalDateTime',
        'tokenInvalidationAt': 'LocalDateTime',
        'tokenRole': 'string',
        'username': 'string'
    });
}
var ApinaConfig = /** @class */ (function () {
    function ApinaConfig() {
        /** Prefix added for all API calls */
        this.baseUrl = "";
        this.serializers = {
            any: identitySerializer,
            string: identitySerializer,
            number: identitySerializer,
            boolean: identitySerializer
        };
        registerDefaultSerializers(this);
    }
    ApinaConfig.prototype.serialize = function (value, type) {
        return this.lookupSerializer(type).serialize(value);
    };
    ApinaConfig.prototype.deserialize = function (value, type) {
        return this.lookupSerializer(type).deserialize(value);
    };
    ApinaConfig.prototype.registerSerializer = function (name, serializer) {
        this.serializers[name] = serializer;
    };
    ApinaConfig.prototype.registerEnumSerializer = function (name, enumObject) {
        this.registerSerializer(name, enumSerializer(enumObject));
    };
    ApinaConfig.prototype.registerClassSerializer = function (name, fields) {
        this.registerSerializer(name, this.classSerializer(fields));
    };
    ApinaConfig.prototype.registerIdentitySerializer = function (name) {
        this.registerSerializer(name, identitySerializer);
    };
    ApinaConfig.prototype.registerDiscriminatedUnionSerializer = function (name, discriminator, types) {
        this.registerSerializer(name, this.discriminatedUnionSerializer(discriminator, types));
    };
    ApinaConfig.prototype.classSerializer = function (fields) {
        function mapProperties(obj, propertyMapper) {
            if (obj === null || obj === undefined) {
                return obj;
            }
            var result = {};
            for (var name_1 in fields) {
                if (fields.hasOwnProperty(name_1)) {
                    var value = obj[name_1];
                    var type = fields[name_1];
                    result[name_1] = propertyMapper(value, type);
                }
            }
            return result;
        }
        var serialize = this.serialize.bind(this);
        var deserialize = this.deserialize.bind(this);
        return {
            serialize: function (obj) {
                return mapProperties(obj, serialize);
            },
            deserialize: function (obj) {
                return mapProperties(obj, deserialize);
            }
        };
    };
    ApinaConfig.prototype.discriminatedUnionSerializer = function (discriminatorProperty, types) {
        var _this = this;
        var resolveSerializer = function (localType) {
            return _this.lookupSerializer(types[localType]);
        };
        return {
            serialize: function (obj) {
                if (obj == null)
                    return null;
                var localType = obj[discriminatorProperty];
                var result = resolveSerializer(localType).serialize(obj);
                result[discriminatorProperty] = localType;
                return result;
            },
            deserialize: function (obj) {
                if (obj == null)
                    return null;
                var localType = obj[discriminatorProperty];
                var result = resolveSerializer(localType).deserialize(obj);
                result[discriminatorProperty] = localType;
                return result;
            }
        };
    };
    ApinaConfig.prototype.lookupSerializer = function (type) {
        if (!type)
            throw new Error("no type given");
        if (type.indexOf('[]', type.length - 2) !== -1) {
            var elementType = type.substring(0, type.length - 2);
            var elementSerializer = this.lookupSerializer(elementType);
            return arraySerializer(elementSerializer);
        }
        var dictionaryMatched = /^Dictionary<(.+)>$/.exec(type);
        if (dictionaryMatched) {
            return dictionarySerializer(this.lookupSerializer(dictionaryMatched[1]));
        }
        var serializer = this.serializers[type];
        if (serializer) {
            return serializer;
        }
        else {
            throw new Error("could not find serializer for type '" + type + "'");
        }
    };
    return ApinaConfig;
}());
export { ApinaConfig };
function arraySerializer(elementSerializer) {
    function safeMap(value, mapper) {
        if (!value)
            return value;
        else
            return value.map(mapper);
    }
    var serialize = elementSerializer.serialize.bind(elementSerializer);
    var deserialize = elementSerializer.deserialize.bind(elementSerializer);
    return {
        serialize: function (value) {
            return safeMap(value, serialize);
        },
        deserialize: function (value) {
            return safeMap(value, deserialize);
        }
    };
}
function dictionarySerializer(valueSerializer) {
    function safeMap(dictionary, mapper) {
        if (!dictionary)
            return dictionary;
        else {
            var result = {};
            for (var key in dictionary) {
                if (dictionary.hasOwnProperty(key)) {
                    result[key] = mapper(dictionary[key]);
                }
            }
            return result;
        }
    }
    var serialize = valueSerializer.serialize.bind(valueSerializer);
    var deserialize = valueSerializer.deserialize.bind(valueSerializer);
    return {
        serialize: function (value) {
            return safeMap(value, serialize);
        },
        deserialize: function (value) {
            return safeMap(value, deserialize);
        }
    };
}
function formatQueryParameters(params) {
    var queryParameters = [];
    var addQueryParameter = function (encodedKey, value) {
        if (value != null)
            queryParameters.push(encodedKey + "=" + encodeURIComponent(value));
    };
    for (var _i = 0, _a = Object.keys(params || {}); _i < _a.length; _i++) {
        var key = _a[_i];
        var value = params[key];
        var encodedKey = encodeURIComponent(key);
        if (Array.isArray(value)) {
            for (var _b = 0, value_1 = value; _b < value_1.length; _b++) {
                var arrayItemValue = value_1[_b];
                addQueryParameter(encodedKey, arrayItemValue);
            }
        }
        else {
            addQueryParameter(encodedKey, value);
        }
    }
    return queryParameters.length > 0 ? '?' + queryParameters.join('&') : '';
}
var identitySerializer = {
    serialize: function (o) {
        return o;
    },
    deserialize: function (o) {
        return o;
    }
};
function enumSerializer(enumObject) {
    return {
        serialize: function (o) {
            if (o === null || o === undefined)
                return o;
            else
                return enumObject[o];
        },
        deserialize: function (o) {
            if (o === null || o === undefined)
                return o;
            else
                return enumObject[o];
        }
    };
}
var ApinaEndpointContext = /** @class */ (function () {
    function ApinaEndpointContext(config) {
        this.config = config;
    }
    ApinaEndpointContext.prototype.url = function (data) {
        var url = this.buildUrl(data.uriTemplate, data.pathVariables);
        return url + formatQueryParameters(data.requestParams);
    };
    ApinaEndpointContext.prototype.serialize = function (value, type) {
        return this.config.serialize(value, type);
    };
    ApinaEndpointContext.prototype.deserialize = function (value, type) {
        return this.config.deserialize(value, type);
    };
    ApinaEndpointContext.prototype.buildUrl = function (uriTemplate, pathVariables) {
        return this.config.baseUrl + uriTemplate.replace(/{([^}]+)}/g, function (match, name) { return pathVariables[name]; });
    };
    return ApinaEndpointContext;
}());
export { ApinaEndpointContext };
var DefaultApinaEndpointContext = /** @class */ (function (_super) {
    __extends(DefaultApinaEndpointContext, _super);
    function DefaultApinaEndpointContext(httpClient, config) {
        var _this = _super.call(this, config) || this;
        _this.httpClient = httpClient;
        return _this;
    }
    DefaultApinaEndpointContext.prototype.request = function (data) {
        var _this = this;
        var url = this.buildUrl(data.uriTemplate, data.pathVariables);
        var requestParams = data.requestParams;
        var params = undefined;
        if (requestParams != null) {
            var filteredParams = {};
            for (var _i = 0, _a = Object.keys(requestParams); _i < _a.length; _i++) {
                var key = _a[_i];
                var value = requestParams[key];
                if (value != null)
                    filteredParams[key] = value;
            }
            params = new HttpParams({ fromObject: filteredParams });
        }
        return this.httpClient.request(data.method, url, { params: params, body: data.requestBody })
            .pipe(map(function (r) { return data.responseType ? _this.config.deserialize(r, data.responseType) : r; }));
    };
    return DefaultApinaEndpointContext;
}(ApinaEndpointContext));
export { DefaultApinaEndpointContext };
var FrontendEndpoint = /** @class */ (function () {
    function FrontendEndpoint(context) {
        this.context = context;
    }
    FrontendEndpoint.prototype.acceptUserRequest = function (id) {
        return this.context.request({
            'uriTemplate': '/api/request/{id}',
            'method': 'POST',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    };
    FrontendEndpoint.prototype.addUserRequest = function (request) {
        return this.context.request({
            'uriTemplate': '/api/request',
            'method': 'POST',
            'requestBody': this.context.serialize(request, 'UserRequestModel')
        });
    };
    FrontendEndpoint.prototype.createOrganization = function (createOrganization) {
        return this.context.request({
            'uriTemplate': '/api/organization',
            'method': 'POST',
            'requestBody': this.context.serialize(createOrganization, 'CreateOrganization'),
            'responseType': 'UUID'
        });
    };
    FrontendEndpoint.prototype.createToken = function () {
        return this.context.request({
            'uriTemplate': '/api/token',
            'method': 'POST',
            'responseType': 'TokenModel'
        });
    };
    FrontendEndpoint.prototype.declineUserRequest = function (id) {
        return this.context.request({
            'uriTemplate': '/api/request/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    };
    FrontendEndpoint.prototype.deleteToken = function () {
        return this.context.request({
            'uriTemplate': '/api/token',
            'method': 'DELETE',
            'responseType': 'boolean'
        });
    };
    FrontendEndpoint.prototype.getAllRoles = function () {
        return this.context.request({
            'uriTemplate': '/api/roles',
            'method': 'GET',
            'responseType': 'string[]'
        });
    };
    FrontendEndpoint.prototype.getAllUserRequests = function () {
        return this.context.request({
            'uriTemplate': '/api/requests',
            'method': 'GET',
            'responseType': 'UserRequestWithOrganization[]'
        });
    };
    FrontendEndpoint.prototype.getAuthenticatedUser = function () {
        return this.context.request({
            'uriTemplate': '/api/authenticated-user',
            'method': 'GET',
            'responseType': 'YtiUser'
        });
    };
    FrontendEndpoint.prototype.getConfiguration = function () {
        return this.context.request({
            'uriTemplate': '/api/config',
            'method': 'GET',
            'responseType': 'ConfigurationModel'
        });
    };
    FrontendEndpoint.prototype.getOrganization = function (id) {
        return this.context.request({
            'uriTemplate': '/api/organization/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'UUID')
            },
            'responseType': 'OrganizationWithUsers'
        });
    };
    FrontendEndpoint.prototype.getOrganizations = function () {
        return this.context.request({
            'uriTemplate': '/api/organizations',
            'method': 'GET',
            'responseType': 'OrganizationListItem[]'
        });
    };
    FrontendEndpoint.prototype.getOrganizationsOpt = function (showRemoved) {
        return this.context.request({
            'uriTemplate': '/api/organizations/{showRemoved}',
            'method': 'GET',
            'pathVariables': {
                'showRemoved': this.context.serialize(showRemoved, 'boolean')
            },
            'responseType': 'OrganizationListItem[]'
        });
    };
    FrontendEndpoint.prototype.getOrganizationsWithChildren = function () {
        return this.context.request({
            'uriTemplate': '/api/organizations/withChildren',
            'method': 'GET',
            'responseType': 'OrganizationListItem[]'
        });
    };
    FrontendEndpoint.prototype.getTestUsers = function () {
        return this.context.request({
            'uriTemplate': '/api/testUsers',
            'method': 'GET',
            'responseType': 'UserWithRolesInOrganizations[]'
        });
    };
    FrontendEndpoint.prototype.getUsers = function () {
        return this.context.request({
            'uriTemplate': '/api/users',
            'method': 'GET',
            'responseType': 'UserWithRolesInOrganizations[]'
        });
    };
    FrontendEndpoint.prototype.getUsersForOwnOrganizations = function () {
        return this.context.request({
            'uriTemplate': '/api/usersForOwnOrganizations',
            'method': 'GET',
            'responseType': 'UserWithRolesInOrganizations[]'
        });
    };
    FrontendEndpoint.prototype.getVersion = function () {
        return this.context.request({
            'uriTemplate': '/api/version',
            'method': 'GET',
            'responseType': 'VersionInformation'
        });
    };
    FrontendEndpoint.prototype.removeUser = function (email) {
        return this.context.request({
            'uriTemplate': '/api/removeuser/{email}/',
            'method': 'POST',
            'pathVariables': {
                'email': this.context.serialize(email, 'string')
            },
            'responseType': 'boolean'
        });
    };
    FrontendEndpoint.prototype.updateOrganization = function (updateOrganization) {
        return this.context.request({
            'uriTemplate': '/api/organization',
            'method': 'PUT',
            'requestBody': this.context.serialize(updateOrganization, 'UpdateOrganization')
        });
    };
    return FrontendEndpoint;
}());
export { FrontendEndpoint };
var PrivateApiEndpoint = /** @class */ (function () {
    function PrivateApiEndpoint(context) {
        this.context = context;
    }
    PrivateApiEndpoint.prototype.addUserRequest = function (userId, organizationId, role) {
        return this.context.request({
            'uriTemplate': '/private-api/request',
            'method': 'POST',
            'requestParams': {
                'userId': this.context.serialize(userId, 'UUID'),
                'organizationId': this.context.serialize(organizationId, 'UUID'),
                'role': this.context.serialize(role, 'string')
            }
        });
    };
    PrivateApiEndpoint.prototype.createTempUsers = function (containerUri, tempUsers) {
        return this.context.request({
            'uriTemplate': '/private-api/tempusers',
            'method': 'POST',
            'requestParams': {
                'containerUri': this.context.serialize(containerUri, 'string')
            },
            'requestBody': this.context.serialize(tempUsers, 'TempUser[]'),
            'responseType': 'PublicApiUser[]'
        });
    };
    PrivateApiEndpoint.prototype.getChildOrganizations = function (parentId) {
        return this.context.request({
            'uriTemplate': '/private-api/childorganizations',
            'method': 'GET',
            'requestParams': {
                'parentId': this.context.serialize(parentId, 'UUID')
            },
            'responseType': 'PublicApiOrganization[]'
        });
    };
    PrivateApiEndpoint.prototype.getParentOrganization = function (childOrganizationId) {
        return this.context.request({
            'uriTemplate': '/private-api/parentorganization',
            'method': 'GET',
            'requestParams': {
                'childOrganizationId': this.context.serialize(childOrganizationId, 'UUID')
            },
            'responseType': 'PublicApiOrganization'
        });
    };
    PrivateApiEndpoint.prototype.getTempUsers = function () {
        return this.context.request({
            'uriTemplate': '/private-api/tempusers',
            'method': 'GET',
            'responseType': 'PrivateApiTempUserListItem[]'
        });
    };
    PrivateApiEndpoint.prototype.getUserByEmail = function (newUser) {
        return this.context.request({
            'uriTemplate': '/private-api/user',
            'method': 'POST',
            'requestBody': this.context.serialize(newUser, 'NewUser'),
            'responseType': 'PublicApiUser'
        });
    };
    PrivateApiEndpoint.prototype.getUserRequests = function (userId) {
        return this.context.request({
            'uriTemplate': '/private-api/requests',
            'method': 'GET',
            'requestParams': {
                'userId': this.context.serialize(userId, 'UUID')
            },
            'responseType': 'PublicApiUserRequest[]'
        });
    };
    PrivateApiEndpoint.prototype.getUsers = function () {
        return this.context.request({
            'uriTemplate': '/private-api/users',
            'method': 'GET',
            'responseType': 'PublicApiUserListItem[]'
        });
    };
    PrivateApiEndpoint.prototype.sendContainerTempUserEmails = function (containerUri) {
        return this.context.request({
            'uriTemplate': '/private-api/sendcontaineremails',
            'method': 'POST',
            'requestParams': {
                'containerUri': this.context.serialize(containerUri, 'string')
            },
            'responseType': 'number'
        });
    };
    PrivateApiEndpoint.prototype.validateUserToken = function (token) {
        return this.context.request({
            'uriTemplate': '/private-api/validate',
            'method': 'POST',
            'requestBody': this.context.serialize(token, 'TokenModel'),
            'responseType': 'YtiUser'
        });
    };
    return PrivateApiEndpoint;
}());
export { PrivateApiEndpoint };
var PublicApiEndpoint = /** @class */ (function () {
    function PublicApiEndpoint(context) {
        this.context = context;
    }
    PublicApiEndpoint.prototype.findUserById = function (id) {
        return this.context.request({
            'uriTemplate': '/public-api/user',
            'method': 'GET',
            'requestParams': {
                'id': this.context.serialize(id, 'UUID')
            },
            'responseType': 'PublicApiUser'
        });
    };
    PublicApiEndpoint.prototype.getOrganizations = function (onlyValid, ifModifiedSinceParam) {
        return this.context.request({
            'uriTemplate': '/public-api/organizations',
            'method': 'GET',
            'requestParams': {
                'onlyValid': this.context.serialize(onlyValid, 'boolean'),
                'ifModifiedSince': this.context.serialize(ifModifiedSinceParam, 'string')
            },
            'responseType': 'PublicApiOrganization[]'
        });
    };
    PublicApiEndpoint.prototype.getUsers = function () {
        return this.context.request({
            'uriTemplate': '/public-api/users',
            'method': 'GET',
            'responseType': 'PublicApiUserListItem[]'
        });
    };
    return PublicApiEndpoint;
}());
export { PublicApiEndpoint };
var SystemApiEndpoint = /** @class */ (function () {
    function SystemApiEndpoint(context) {
        this.context = context;
    }
    return SystemApiEndpoint;
}());
export { SystemApiEndpoint };
export function apinaConfigFactory() {
    return new ApinaConfig();
}
var ApinaModule = /** @class */ (function () {
    function ApinaModule() {
    }
    return ApinaModule;
}());
export { ApinaModule };
