    
    <app-ajax-loading-indicator *ngIf="loading"></app-ajax-loading-indicator>
    
    <div *ngIf="!loading">

      <h1 translate>Organizations</h1>

      <div class="top-actions">

        <div class="input-group input-group-lg input-group-search float-left">
          <input class="form-control"
                 id="search_organization_input"
                 type="text"
                 [(ngModel)]="search"
                 placeholder="{{'Search organization' | translate}}"/>
        </div>

        <div>
          <input #showRemovedCheckBox id="showRemovedCheckBox" id="show_removed_organizations_checkbox" type="checkbox" name="showRemovedCheckBox"
                 (change)="showRemovedChanged()"/>
                 <span id="show_removed_text_span" translate>Show removed organizations only</span>
        </div>
        
        <button class="btn btn-action float-right" id="add_new_organization_button" (click)="addOrganization()"
                *ngIf="canCreateOrganization()">
          <span translate>Add new organization</span>
        </button>
      </div>      

      <div *ngFor="let organization of filteredOrganizations"
           id="{{'organization_list_item_' + organization.id}}"
           class="organization"
           [class.viewable]="canViewOrganization(organization)"
           (click)="navigate(organization)">

        <span class="name">{{organization.name | translateValue}}</span>
      </div>
    </div>
  