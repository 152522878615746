import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationManager } from '../services/authorization-manager.service';
import { ApiService } from '../services/api.service';
import { LanguageService } from '../services/language.service';
import { comparingLocalizable } from 'yti-common-ui/utils/comparator';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { matches } from 'yti-common-ui/utils/string';
var OrganizationsComponent = /** @class */ (function () {
    function OrganizationsComponent(apiService, authorizationManager, router, languageService) {
        var _this = this;
        this.apiService = apiService;
        this.authorizationManager = authorizationManager;
        this.router = router;
        this.languageService = languageService;
        this.search$ = new BehaviorSubject('');
        this.subscription = combineLatest(apiService.getOrganizationListOpt(false), this.search$, languageService.language$)
            .subscribe(function (_a) {
            var organizations = _a[0], search = _a[1];
            organizations.sort(comparingLocalizable(languageService, function (org) { return org.name; }));
            _this.filteredOrganizations = organizations.filter(function (org) { return matches(languageService.translate(org.name), search); });
        });
    }
    Object.defineProperty(OrganizationsComponent.prototype, "loading", {
        get: function () {
            return this.filteredOrganizations == null;
        },
        enumerable: true,
        configurable: true
    });
    OrganizationsComponent.prototype.canViewOrganization = function (organization) {
        return this.authorizationManager.canViewOrganization(organization.id);
    };
    OrganizationsComponent.prototype.canCreateOrganization = function () {
        return this.authorizationManager.canCreateOrganization();
    };
    Object.defineProperty(OrganizationsComponent.prototype, "search", {
        get: function () {
            return this.search$.getValue();
        },
        set: function (value) {
            this.search$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    OrganizationsComponent.prototype.navigate = function (organization) {
        if (this.canViewOrganization(organization)) {
            this.router.navigate(['/organization', organization.id]);
        }
    };
    OrganizationsComponent.prototype.showRemovedChanged = function () {
        var _this = this;
        this.showRemovedCheckBox = !this.showRemovedCheckBox;
        this.subscription.unsubscribe();
        this.subscription = combineLatest(this.apiService.getOrganizationListOpt(this.showRemovedCheckBox), this.search$, this.languageService.language$)
            .subscribe(function (_a) {
            var organizations = _a[0], search = _a[1];
            organizations.sort(comparingLocalizable(_this.languageService, function (org) { return org.name; }));
            _this.filteredOrganizations = organizations.filter(function (org) { return matches(_this.languageService.translate(org.name), search); });
        });
    };
    OrganizationsComponent.prototype.addOrganization = function () {
        this.router.navigate(['/newOrganization']);
    };
    OrganizationsComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return OrganizationsComponent;
}());
export { OrganizationsComponent };
