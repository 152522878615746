
    <div class="modal-header modal-header-warning">
      <h4 class="modal-title">
        <a><i class="fa fa-times" id="delete_modal_cancel_link" (click)="cancel()"></i></a>
        <span translate>Confirm remove</span>
      </h4>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">

            <p>{{contenttext | translate}}</p>
            <li>{{username}}  ({{useremail}})</li>
          <br>
          <span translate>Are you sure that you want to remove?</span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" id="delete_yes_button" class="btn btn-action confirm" (click)="confirm()" translate>Yes</button>
      <button type="button" id="delete_cancel_button" class="btn btn-link cancel" (click)="cancel()" translate>Cancel</button>
    </div>
  