var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { CanDeactivate, ResolveEnd, Router, Routes } from '@angular/router';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader } from '@ngx-translate/core';
import { ApinaConfig } from './apina';
import { FrontpageComponent } from './components/frontpage.component';
import { NewOrganizationComponent } from './components/new-organization.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { OrganizationComponent } from './components/organization.component';
import { ConfirmationModalService } from 'yti-common-ui/components/confirmation-modal.component';
import { InformationAboutServiceComponent } from './components/information/information-about-service.component';
import { ModalService } from 'yti-common-ui/services/modal.service';
import { of } from 'rxjs';
import { ConfigurationService } from './services/configuration.service';
function removeEmptyValues(obj) {
    var result = {};
    for (var _i = 0, _a = Object.entries(obj); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], value = _b[1];
        if (!!value) {
            result[key] = value;
        }
    }
    return result;
}
var localizations = {
    fi: __assign({}, removeEmptyValues(JSON.parse(require("raw-loader!po-loader?format=mf!../../po/fi.po"))), removeEmptyValues(JSON.parse(require("raw-loader!po-loader?format=mf!yti-common-ui/po/fi.po")))),
    en: __assign({}, removeEmptyValues(JSON.parse(require("raw-loader!po-loader?format=mf!../../po/en.po"))), removeEmptyValues(JSON.parse(require("raw-loader!po-loader?format=mf!yti-common-ui/po/en.po")))),
    sv: __assign({}, removeEmptyValues(JSON.parse(require("raw-loader!po-loader?format=mf!../../po/sv.po"))), removeEmptyValues(JSON.parse(require("raw-loader!po-loader?format=mf!yti-common-ui/po/sv.po"))))
};
export function initApp(configurationService) {
    return function () { return configurationService.fetchConfiguration(); };
}
export function resolveAuthenticatedUserEndpoint() {
    return '/api/authenticated-user';
}
export function createTranslateLoader() {
    return { getTranslation: function (lang) { return of(localizations[lang]); } };
}
export function createMissingTranslationHandler() {
    return {
        handle: function (params) {
            if (params.translateService.currentLang === 'en') {
                return params.key;
            }
            else {
                return '[MISSING]: ' + params.key;
            }
        }
    };
}
var ConfirmCancelEditGuard = /** @class */ (function () {
    function ConfirmCancelEditGuard(confirmationModalService) {
        this.confirmationModalService = confirmationModalService;
    }
    ConfirmCancelEditGuard.prototype.canDeactivate = function (target) {
        if (!target.hasChanges()) {
            return Promise.resolve(true);
        }
        else {
            return this.confirmationModalService.openEditInProgress().then(function () { return true; }, function () { return false; });
        }
    };
    return ConfirmCancelEditGuard;
}());
export { ConfirmCancelEditGuard };
var ConfirmModalCloseEditGuard = /** @class */ (function () {
    function ConfirmModalCloseEditGuard(modalService, confirmationModalService) {
        this.modalService = modalService;
        this.confirmationModalService = confirmationModalService;
    }
    ConfirmModalCloseEditGuard.prototype.canDeactivate = function (target) {
        if (!this.modalService.isModalOpen()) {
            return Promise.resolve(true);
        }
        else {
            return this.confirmationModalService.openModalClose().then(function () { return true; }, function () { return false; });
        }
    };
    return ConfirmModalCloseEditGuard;
}());
export { ConfirmModalCloseEditGuard };
var appRoutes = [
    { path: '', component: FrontpageComponent },
    {
        path: 'newOrganization',
        component: NewOrganizationComponent,
        canDeactivate: [ConfirmModalCloseEditGuard, ConfirmCancelEditGuard],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'newOrganization/:parentId',
        component: NewOrganizationComponent,
        canDeactivate: [ConfirmModalCloseEditGuard, ConfirmCancelEditGuard],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'organization/:id',
        component: OrganizationComponent,
        canDeactivate: [ConfirmModalCloseEditGuard, ConfirmCancelEditGuard],
        runGuardsAndResolvers: 'always'
    },
    { path: 'userDetails', component: UserDetailsComponent },
    { path: 'information', component: InformationAboutServiceComponent }
];
var AppModule = /** @class */ (function () {
    function AppModule(apinaConfig, router, modalService) {
        apinaConfig.registerIdentitySerializer('Dictionary<string>');
        router.events.subscribe(function (event) {
            if (event instanceof ResolveEnd) {
                modalService.closeAllModals();
            }
        });
    }
    return AppModule;
}());
export { AppModule };
