import * as moment from 'moment';
var User = /** @class */ (function () {
    function User(user) {
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.email = user.email;
        this.superuser = user.superuser;
        this.creationDateTime = moment(user.creationDateTime);
        this.organizations = user.organizations;
    }
    Object.defineProperty(User.prototype, "name", {
        get: function () {
            return this.firstName + ' ' + this.lastName;
        },
        enumerable: true,
        configurable: true
    });
    return User;
}());
export { User };
