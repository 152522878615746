var OrganizationDetails = /** @class */ (function () {
    function OrganizationDetails(url, nameFi, nameEn, nameSv, descriptionFi, descriptionEn, descriptionSv, removed, parentId, childOrganizations) {
        this.url = url;
        this.nameFi = nameFi;
        this.nameEn = nameEn;
        this.nameSv = nameSv;
        this.descriptionFi = descriptionFi;
        this.descriptionEn = descriptionEn;
        this.descriptionSv = descriptionSv;
        this.removed = removed;
        this.parentId = parentId;
        this.childOrganizations = childOrganizations;
    }
    OrganizationDetails.empty = function () {
        return new OrganizationDetails('', '', '', '', '', '', '', false, '', []);
    };
    OrganizationDetails.emptyChildOrganization = function (parentId) {
        return new OrganizationDetails('', '', '', '', '', '', '', false, parentId, []);
    };
    OrganizationDetails.fromOrganization = function (model, childOrganizations) {
        return new OrganizationDetails(model.url, model.nameFi, model.nameEn, model.nameSv, model.descriptionFi, model.descriptionEn, model.descriptionSv, model.removed, model.parentId, childOrganizations);
    };
    Object.defineProperty(OrganizationDetails.prototype, "name", {
        get: function () {
            return {
                'fi': this.nameFi,
                'en': this.nameEn,
                'sv': this.nameSv
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrganizationDetails.prototype, "description", {
        get: function () {
            return {
                'fi': this.descriptionFi,
                'en': this.descriptionEn,
                'sv': this.descriptionSv
            };
        },
        enumerable: true,
        configurable: true
    });
    OrganizationDetails.prototype.clone = function () {
        return new OrganizationDetails(this.url, this.nameFi, this.nameEn, this.nameSv, this.descriptionFi, this.descriptionEn, this.descriptionSv, this.removed, this.parentId, this.childOrganizations);
    };
    return OrganizationDetails;
}());
export { OrganizationDetails };
