/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-requests.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../pipes/translate-value.pipe";
import * as i3 from "../services/language.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./user-requests.component";
import * as i7 from "../services/api.service";
import * as i8 from "yti-common-ui/components/error-modal.component";
var styles_UserRequestsComponent = [i0.styles];
var RenderType_UserRequestsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserRequestsComponent, data: {} });
export { RenderType_UserRequestsComponent as RenderType_UserRequestsComponent };
function View_UserRequestsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.TranslateValuePipe, [i3.LanguageService]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 8, "td", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "button", [["class", "btn btn-link"], ["type", "button"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.declineRequest(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "fa fa-trash"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(15, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Decline"])), (_l()(), i1.ɵeld(17, 0, null, null, 2, "button", [["class", "btn btn-action"], ["translate", ""], ["type", "button"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.acceptRequest(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Accept"]))], function (_ck, _v) { var currVal_5 = ""; _ck(_v, 15, 0, currVal_5); var currVal_7 = ""; _ck(_v, 18, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.fullName; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.email; _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_v.context.$implicit.organizationName)); _ck(_v, 6, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_v.context.$implicit.role)); _ck(_v, 9, 0, currVal_3); var currVal_4 = i1.ɵinlineInterpolate(1, "", (_v.context.$implicit.email + "_decline_request_button"), ""); _ck(_v, 12, 0, currVal_4); var currVal_6 = i1.ɵinlineInterpolate(1, "", (_v.context.$implicit.email + "_accept_request_button"), ""); _ck(_v, 17, 0, currVal_6); }); }
function View_UserRequestsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Access requests"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "table", [["class", "table table-striped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserRequestsComponent_2)), i1.ɵdid(7, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.userRequests; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_UserRequestsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserRequestsComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.userRequests.length > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_UserRequestsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-requests", [], null, null, null, View_UserRequestsComponent_0, RenderType_UserRequestsComponent)), i1.ɵdid(1, 49152, null, 0, i6.UserRequestsComponent, [i7.ApiService, i8.ErrorModalService], null, null)], null, null); }
var UserRequestsComponentNgFactory = i1.ɵccf("app-user-requests", i6.UserRequestsComponent, View_UserRequestsComponent_Host_0, {}, {}, []);
export { UserRequestsComponentNgFactory as UserRequestsComponentNgFactory };
