/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./new-organization.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../../node_modules/yti-common-ui/components/back-button.component.ngfactory";
import * as i5 from "yti-common-ui/components/back-button.component";
import * as i6 from "yti-common-ui/components/notification.component";
import * as i7 from "./organization-details.component.ngfactory";
import * as i8 from "./organization-details.component";
import * as i9 from "../services/authorization-manager.service";
import * as i10 from "./new-organization.component";
import * as i11 from "../services/location.service";
import * as i12 from "./search-user-modal.component";
import * as i13 from "../services/api.service";
import * as i14 from "@angular/router";
var styles_NewOrganizationComponent = [i0.styles];
var RenderType_NewOrganizationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewOrganizationComponent, data: {} });
export { RenderType_NewOrganizationComponent as RenderType_NewOrganizationComponent };
function View_NewOrganizationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["No admin users yet"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_NewOrganizationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", (_v.context.$implicit.email + "_org_admin_user_item"), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_1); }); }
function View_NewOrganizationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewOrganizationComponent_3)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.organizationAdminUsers; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NewOrganizationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { notification: 0 }), i1.ɵqud(402653184, 2, { details: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 25, "div", [["class", "content-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-back-button", [], null, [[null, "back"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("back" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_BackButtonComponent_0, i4.RenderType_BackButtonComponent)), i1.ɵdid(4, 49152, null, 0, i5.BackButtonComponent, [], null, { back: "back" }), (_l()(), i1.ɵeld(5, 0, null, null, 10, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h1", [["class", "float-left"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(7, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["New organization"])), (_l()(), i1.ɵeld(9, 16777216, null, null, 3, "button", [["appNotification", ""], ["class", "btn btn-action float-right"], ["id", "save_organization_button"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveOrganization() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, [[1, 4], ["notification", 4]], 0, i6.NotificationDirective, [i1.ViewContainerRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver], null, null), (_l()(), i1.ɵted(11, null, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn-link cancel float-right"], ["id", "cancel_button"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 1, "app-organization-details", [["id", "organization_details"]], null, null, null, i7.View_OrganizationDetailsComponent_0, i7.RenderType_OrganizationDetailsComponent)), i1.ɵdid(17, 49152, [[2, 4], ["details", 4]], 0, i8.OrganizationDetailsComponent, [i9.AuthorizationManager], { organization: [0, "organization"], editing: [1, "editing"], parentOrganization: [2, "parentOrganization"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 2, "h3", [["class", "mt-4"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(19, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Admin users"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewOrganizationComponent_1)), i1.ɵdid(22, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewOrganizationComponent_2)), i1.ɵdid(24, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 2, "button", [["class", "btn btn-action"], ["id", "add_user_button"], ["translate", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addUser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(26, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Add user"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 7, 0, currVal_0); var currVal_4 = _co.organization; var currVal_5 = true; var currVal_6 = _co.parentOrganization; _ck(_v, 17, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = ""; _ck(_v, 19, 0, currVal_7); var currVal_8 = (_co.organizationAdminUsers.length === 0); _ck(_v, 22, 0, currVal_8); var currVal_9 = (_co.organizationAdminUsers.length > 0); _ck(_v, 24, 0, currVal_9); var currVal_10 = ""; _ck(_v, 26, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isValid(); _ck(_v, 9, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("Save")); _ck(_v, 11, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("Cancel")); _ck(_v, 14, 0, currVal_3); }); }
export function View_NewOrganizationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-new-organization", [], null, null, null, View_NewOrganizationComponent_0, RenderType_NewOrganizationComponent)), i1.ɵdid(1, 49152, null, 0, i10.NewOrganizationComponent, [i11.LocationService, i12.SearchUserModalService, i13.ApiService, i14.Router, i14.ActivatedRoute, i2.TranslateService], null, null)], null, null); }
var NewOrganizationComponentNgFactory = i1.ɵccf("app-new-organization", i10.NewOrganizationComponent, View_NewOrganizationComponent_Host_0, {}, {}, []);
export { NewOrganizationComponentNgFactory as NewOrganizationComponentNgFactory };
