import { LocationService } from '../services/location.service';
import { SearchUserModalService } from './search-user-modal.component';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { User } from '../entities/user';
import { OrganizationDetails } from '../entities/organization-details';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { NotificationDirective } from 'yti-common-ui/components/notification.component';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationDetailsComponent } from './organization-details.component';
import { flatMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { UserWithRolesInOrganizations } from '../apina';
var NewOrganizationComponent = /** @class */ (function () {
    function NewOrganizationComponent(locationService, searchModal, apiService, router, activatedRoute, translateService) {
        var _this = this;
        this.searchModal = searchModal;
        this.apiService = apiService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.translateService = translateService;
        this.organization = OrganizationDetails.empty();
        this.organizationAdminUsers = [];
        this.successfullySaved = false;
        locationService.atAddNewOrganization();
        var parentOrganziation$ = activatedRoute.params.pipe(flatMap(function (params) {
            if (params['parentId']) {
                return apiService.getOrganization(params['parentId']);
            }
            return EMPTY;
        }));
        parentOrganziation$.subscribe(function (organization) {
            _this.organization = OrganizationDetails.emptyChildOrganization(organization.organization.id.toString());
            if (translateService.currentLang === 'sv') {
                _this.parentOrganization = organization.organization.nameSv;
            }
            else if (translateService.currentLang === 'en') {
                _this.parentOrganization = organization.organization.nameEn;
            }
            if (!_this.parentOrganization || (_this.parentOrganization && _this.parentOrganization.trim().length === 0)) {
                _this.parentOrganization = organization.organization.nameFi;
            }
            var adminUsers = organization.users
                .filter(function (user) { return user.roles.find(function (role) { return role === 'ADMIN'; }); })
                .map(function (user) {
                var adminUser = new UserWithRolesInOrganizations();
                adminUser.firstName = user.user.firstName;
                adminUser.lastName = user.user.lastName;
                adminUser.email = user.user.email;
                return new User(adminUser);
            });
            _this.organizationAdminUsers = adminUsers;
        });
    }
    Object.defineProperty(NewOrganizationComponent.prototype, "organizationAdminEmails", {
        get: function () {
            return this.organizationAdminUsers.map(function (user) { return user.email; });
        },
        enumerable: true,
        configurable: true
    });
    NewOrganizationComponent.prototype.isValid = function () {
        return this.details.isValid() && this.organizationAdminUsers.length > 0;
    };
    NewOrganizationComponent.prototype.hasChanges = function () {
        return !this.successfullySaved && (this.details.hasChanges() || this.organizationAdminUsers.length > 0);
    };
    NewOrganizationComponent.prototype.addUser = function () {
        var _this = this;
        this.searchModal.open(this.organizationAdminEmails)
            .then(function (user) { return _this.organizationAdminUsers.push(user); }, ignoreModalClose);
    };
    NewOrganizationComponent.prototype.saveOrganization = function () {
        var _this = this;
        this.apiService.createOrganization(this.organization, this.organizationAdminEmails).subscribe({
            next: function (id) {
                _this.successfullySaved = true;
                _this.router.navigate(['/organization', id]);
            },
            error: function () { return _this.notification.showFailure(_this.translateService.instant('Save failed'), 3000, 'left'); },
        });
    };
    NewOrganizationComponent.prototype.back = function () {
        this.router.navigate(['/']);
    };
    return NewOrganizationComponent;
}());
export { NewOrganizationComponent };
