/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./organizations.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/yti-common-ui/components/ajax-loading-indicator.component.ngfactory";
import * as i3 from "yti-common-ui/components/ajax-loading-indicator.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../pipes/translate-value.pipe";
import * as i6 from "../services/language.service";
import * as i7 from "@angular/forms";
import * as i8 from "@angular/common";
import * as i9 from "./organizations.component";
import * as i10 from "../services/api.service";
import * as i11 from "../services/authorization-manager.service";
import * as i12 from "@angular/router";
var styles_OrganizationsComponent = [i0.styles];
var RenderType_OrganizationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrganizationsComponent, data: {} });
export { RenderType_OrganizationsComponent as RenderType_OrganizationsComponent };
function View_OrganizationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ajax-loading-indicator", [], null, null, null, i2.View_AjaxLoadingIndicatorComponent_0, i2.RenderType_AjaxLoadingIndicatorComponent)), i1.ɵdid(1, 49152, null, 0, i3.AjaxLoadingIndicatorComponent, [], null, null)], null, null); }
function View_OrganizationsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-action float-right"], ["id", "add_new_organization_button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addOrganization() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Add new organization"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, null); }
function View_OrganizationsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "organization"]], [[8, "id", 0], [2, "viewable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigate(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i5.TranslateValuePipe, [i6.LanguageService])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", ("organization_list_item_" + _v.context.$implicit.id), ""); var currVal_1 = _co.canViewOrganization(_v.context.$implicit); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_v.context.$implicit.name)); _ck(_v, 2, 0, currVal_2); }); }
function View_OrganizationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Organizations"])), (_l()(), i1.ɵeld(4, 0, null, null, 15, "div", [["class", "top-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "input-group input-group-lg input-group-search float-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "input", [["class", "form-control"], ["id", "search_organization_input"], ["type", "text"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.search = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i7.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i7.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DefaultValueAccessor]), i1.ɵdid(9, 671744, null, 0, i7.NgModel, [[8, null], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(11, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, [["showRemovedCheckBox", 1]], null, 0, "input", [["id", "show_removed_organizations_checkbox"], ["name", "showRemovedCheckBox"], ["type", "checkbox"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.showRemovedChanged() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "span", [["id", "show_removed_text_span"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(16, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Show removed organizations only"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrganizationsComponent_3)), i1.ɵdid(19, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrganizationsComponent_4)), i1.ɵdid(21, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); var currVal_9 = _co.search; _ck(_v, 9, 0, currVal_9); var currVal_10 = ""; _ck(_v, 16, 0, currVal_10); var currVal_11 = _co.canCreateOrganization(); _ck(_v, 19, 0, currVal_11); var currVal_12 = _co.filteredOrganizations; _ck(_v, 21, 0, currVal_12); }, function (_ck, _v) { var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 12).transform("Search organization")), ""); var currVal_2 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 11).ngClassValid; var currVal_7 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_OrganizationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrganizationsComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrganizationsComponent_2)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.loading; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_OrganizationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-organizations", [], null, null, null, View_OrganizationsComponent_0, RenderType_OrganizationsComponent)), i1.ɵdid(1, 180224, null, 0, i9.OrganizationsComponent, [i10.ApiService, i11.AuthorizationManager, i12.Router, i6.LanguageService], null, null)], null, null); }
var OrganizationsComponentNgFactory = i1.ɵccf("app-organizations", i9.OrganizationsComponent, View_OrganizationsComponent_Host_0, {}, {}, []);
export { OrganizationsComponentNgFactory as OrganizationsComponentNgFactory };
