/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delete-confirmation-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./delete-confirmation-modal.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
var styles_DeleteConfirmationModalComponent = [i0.styles];
var RenderType_DeleteConfirmationModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeleteConfirmationModalComponent, data: {} });
export { RenderType_DeleteConfirmationModalComponent as RenderType_DeleteConfirmationModalComponent };
export function View_DeleteConfirmationModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-header modal-header-warning"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-times"], ["id", "delete_modal_cancel_link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(5, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Confirm remove"])), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 9, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", " (", ")"])), (_l()(), i1.ɵeld(15, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(17, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Are you sure that you want to remove?"])), (_l()(), i1.ɵeld(19, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "button", [["class", "btn btn-action confirm"], ["id", "delete_yes_button"], ["translate", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Yes"])), (_l()(), i1.ɵeld(23, 0, null, null, 2, "button", [["class", "btn btn-link cancel"], ["id", "delete_cancel_button"], ["translate", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(24, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 5, 0, currVal_0); var currVal_4 = ""; _ck(_v, 17, 0, currVal_4); var currVal_5 = ""; _ck(_v, 21, 0, currVal_5); var currVal_6 = ""; _ck(_v, 24, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform(_co.contenttext)); _ck(_v, 11, 0, currVal_1); var currVal_2 = _co.username; var currVal_3 = _co.useremail; _ck(_v, 14, 0, currVal_2, currVal_3); }); }
export function View_DeleteConfirmationModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-delete-confirmation-modal", [], null, null, null, View_DeleteConfirmationModalComponent_0, RenderType_DeleteConfirmationModalComponent)), i1.ɵdid(1, 49152, null, 0, i3.DeleteConfirmationModalComponent, [i4.NgbActiveModal], null, null)], null, null); }
var DeleteConfirmationModalComponentNgFactory = i1.ɵccf("app-delete-confirmation-modal", i3.DeleteConfirmationModalComponent, View_DeleteConfirmationModalComponent_Host_0, { username: "username", useremail: "useremail", contenttext: "contenttext" }, {}, []);
export { DeleteConfirmationModalComponentNgFactory as DeleteConfirmationModalComponentNgFactory };
