import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from '../../services/language.service';
import { LocationService } from '../../services/location.service';
import { UserService } from 'yti-common-ui/services/user.service';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { MessagingService } from '../../services/messaging-service';
import { ConfigurationService } from '../../services/configuration.service';
var UserDetailsComponent = /** @class */ (function () {
    function UserDetailsComponent(router, userService, languageService, locationService, messagingService, configurationService) {
        this.router = router;
        this.userService = userService;
        this.languageService = languageService;
        this.messagingService = messagingService;
        this.configurationService = configurationService;
        this.APPLICATION_CODELIST = 'codelist';
        this.APPLICATION_TERMINOLOGY = 'terminology';
        this.APPLICATION_DATAMODEL = 'datamodel';
        this.APPLICATION_COMMENTS = 'comments';
        this.loading = true;
        this.messagingResources$ = new BehaviorSubject(null);
        locationService.atUserDetails();
    }
    UserDetailsComponent.prototype.ngOnInit = function () {
        if (this.configurationService.isMessagingEnabled && this.userService.isLoggedIn()) {
            this.getUserSubscriptionData();
        }
        else {
            this.loading = false;
        }
    };
    UserDetailsComponent.prototype.getUserSubscriptionData = function () {
        var _this = this;
        this.loading = true;
        this.messagingService.getMessagingUserData().subscribe(function (messagingUserData) {
            if (messagingUserData) {
                var resources = new Map();
                var codelistMessagingResources_1 = [];
                var datamodelMessagingResources_1 = [];
                var terminologyMessagingResources_1 = [];
                var commentsMessagingResources_1 = [];
                messagingUserData.resources.forEach(function (resource) {
                    if (resource.application === _this.APPLICATION_CODELIST) {
                        codelistMessagingResources_1.push(resource);
                    }
                    else if (resource.application === _this.APPLICATION_DATAMODEL) {
                        datamodelMessagingResources_1.push(resource);
                    }
                    else if (resource.application === _this.APPLICATION_TERMINOLOGY) {
                        terminologyMessagingResources_1.push(resource);
                    }
                    else if (resource.application === _this.APPLICATION_COMMENTS) {
                        commentsMessagingResources_1.push(resource);
                    }
                });
                if (codelistMessagingResources_1.length > 0) {
                    resources.set(_this.APPLICATION_CODELIST, codelistMessagingResources_1);
                }
                if (datamodelMessagingResources_1.length > 0) {
                    resources.set(_this.APPLICATION_DATAMODEL, datamodelMessagingResources_1);
                }
                if (terminologyMessagingResources_1.length > 0) {
                    resources.set(_this.APPLICATION_TERMINOLOGY, terminologyMessagingResources_1);
                }
                if (commentsMessagingResources_1.length > 0) {
                    resources.set(_this.APPLICATION_COMMENTS, commentsMessagingResources_1);
                }
                if (resources.size > 0) {
                    _this.messagingResources = resources;
                }
                else {
                    _this.messagingResources = null;
                }
            }
            else {
                _this.messagingResources = null;
            }
            _this.loading = false;
        });
    };
    UserDetailsComponent.prototype.onTabChange = function (event) {
        if (event.nextId === 'user_details_info_tab') {
            this.getUserSubscriptionData();
        }
    };
    Object.defineProperty(UserDetailsComponent.prototype, "messagingResources", {
        get: function () {
            return this.messagingResources$.getValue();
        },
        set: function (value) {
            this.messagingResources$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserDetailsComponent.prototype, "user", {
        get: function () {
            return this.userService.user;
        },
        enumerable: true,
        configurable: true
    });
    return UserDetailsComponent;
}());
export { UserDetailsComponent };
