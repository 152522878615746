import { LocationService } from '../services/location.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationDetails } from '../entities/organization-details';
import { EmailRole } from '../apina';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { SearchUserModalService } from './search-user-modal.component';
import { ApiService } from '../services/api.service';
import { DeleteConfirmationModalService } from './delete-confirmation-modal.component';
import { NotificationDirective } from 'yti-common-ui/components/notification.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationManager } from '../services/authorization-manager.service';
import { anyMatching, remove } from 'yti-common-ui/utils/array';
import { ConfirmationModalService } from 'yti-common-ui/components/confirmation-modal.component';
import { OrganizationDetailsComponent } from './organization-details.component';
import { flatMap } from 'rxjs/operators';
var OrganizationComponent = /** @class */ (function () {
    function OrganizationComponent(route, locationService, searchUserModal, deleteUserModal, confirmationModalService, apiService, router, translateService, authorizationManager) {
        var _this = this;
        this.route = route;
        this.searchUserModal = searchUserModal;
        this.deleteUserModal = deleteUserModal;
        this.confirmationModalService = confirmationModalService;
        this.apiService = apiService;
        this.router = router;
        this.translateService = translateService;
        this.authorizationManager = authorizationManager;
        this.usersAddedOrRemoved = false;
        this.editing = false;
        var organizationWithUsers$ = route.params.pipe(flatMap(function (params) {
            var organizationId = params['id'];
            return apiService.getOrganization(organizationId);
        }));
        organizationWithUsers$.subscribe(function (organizationWithUsers) {
            var organizationDetails = OrganizationDetails.fromOrganization(organizationWithUsers.organization, organizationWithUsers.childOrganizations);
            locationService.atOrganization(organizationDetails);
            _this.organizationId = organizationWithUsers.organization.id;
            _this.organization = organizationDetails;
            _this.users = organizationWithUsers.users.map(function (user) {
                return new UserViewModel(user.user.firstName, user.user.lastName, user.user.email, user.roles);
            });
            _this.availableRoles = organizationWithUsers.availableRoles;
        });
    }
    OrganizationComponent.prototype.startEditing = function () {
        this.editing = true;
        this.usersBeforeEditing = this.users.map(function (user) { return user.clone(); });
        this.organizationBeforeEditing = this.organization.clone();
    };
    OrganizationComponent.prototype.cancelEditing = function () {
        this.editing = false;
        this.users = this.usersBeforeEditing;
        this.organization = this.organizationBeforeEditing;
        this.setPristine();
    };
    OrganizationComponent.prototype.isValid = function () {
        return this.details.isValid();
    };
    OrganizationComponent.prototype.hasChanges = function () {
        return this.details.hasChanges() || this.usersAddedOrRemoved || anyMatching(this.users, function (user) { return user.rolesChanged; });
    };
    OrganizationComponent.prototype.setPristine = function () {
        this.details.reset();
        this.usersAddedOrRemoved = false;
        for (var _i = 0, _a = this.users; _i < _a.length; _i++) {
            var user = _a[_i];
            user.setPristine();
        }
    };
    Object.defineProperty(OrganizationComponent.prototype, "notificationVisible", {
        get: function () {
            return this.notification ? this.notification.isOpen() : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrganizationComponent.prototype, "adminUserCount", {
        get: function () {
            return this.users.filter(function (user) { return user.isInRole('ADMIN'); }).length;
        },
        enumerable: true,
        configurable: true
    });
    OrganizationComponent.prototype.isRoleDisabledForUser = function (user, role) {
        return role === 'ADMIN' && this.isUserLastAdmin(user);
    };
    OrganizationComponent.prototype.isUserLastAdmin = function (user) {
        return this.adminUserCount === 1 && user.isInRole('ADMIN');
    };
    OrganizationComponent.prototype.canRemove = function (user) {
        return this.editing && !this.isUserLastAdmin(user);
    };
    Object.defineProperty(OrganizationComponent.prototype, "organizationUserEmails", {
        get: function () {
            return this.users.map(function (user) { return user.email; });
        },
        enumerable: true,
        configurable: true
    });
    OrganizationComponent.prototype.addUser = function () {
        var _this = this;
        this.searchUserModal.open(this.organizationUserEmails)
            .then(function (user) {
            _this.users.push(new UserViewModel(user.firstName, user.lastName, user.email, []));
            _this.usersAddedOrRemoved = true;
        }, ignoreModalClose);
    };
    OrganizationComponent.prototype.removeUser = function (user) {
        var _this = this;
        this.deleteUserModal.open(user.name, user.email, 'This user will be removed from organization after the changes are saved.')
            .then(function () {
            remove(_this.users, user);
            _this.usersAddedOrRemoved = true;
        }, ignoreModalClose);
    };
    OrganizationComponent.prototype.saveOrganization = function () {
        var _this = this;
        var userRoles = [];
        for (var _i = 0, _a = this.users; _i < _a.length; _i++) {
            var user = _a[_i];
            for (var _b = 0, _c = user.roles; _b < _c.length; _b++) {
                var role = _c[_b];
                var emailRole = new EmailRole();
                emailRole.role = role;
                emailRole.userEmail = user.email;
                userRoles.push(emailRole);
            }
        }
        this.apiService.updateOrganization(this.organizationId, this.organization, userRoles).subscribe({
            next: function () {
                _this.notification.showSuccess(_this.translateService.instant('Changes saved'), 3000, 'left');
                _this.editing = false;
                _this.setPristine();
            },
            error: function () { return _this.notification.showFailure(_this.translateService.instant('Save failed'), 3000, 'left'); }
        });
    };
    OrganizationComponent.prototype.back = function () {
        this.router.navigate(['/']);
    };
    OrganizationComponent.prototype.canEditOrganization = function () {
        return this.authorizationManager.canEditOrganization(this.organizationId);
    };
    OrganizationComponent.prototype.canEditChildOrganization = function (organizationId) {
        return this.authorizationManager.canEditOrganization(organizationId);
    };
    OrganizationComponent.prototype.addChildOrganization = function (parentId) {
        this.router.navigate(['/newOrganization', parentId]);
    };
    OrganizationComponent.prototype.viewChildOrganization = function (organizationId) {
        this.router.navigate(['/organization', organizationId]);
    };
    return OrganizationComponent;
}());
export { OrganizationComponent };
var UserViewModel = /** @class */ (function () {
    function UserViewModel(firstName, lastName, email, roles) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.roles = roles;
        this.rolesChanged = false;
    }
    Object.defineProperty(UserViewModel.prototype, "name", {
        get: function () {
            return this.firstName + ' ' + this.lastName;
        },
        enumerable: true,
        configurable: true
    });
    UserViewModel.prototype.isInRole = function (role) {
        return this.roles.indexOf(role) !== -1;
    };
    UserViewModel.prototype.toggleRole = function (role) {
        if (this.isInRole(role)) {
            this.removeRole(role);
        }
        else {
            this.addRole(role);
        }
    };
    Object.defineProperty(UserViewModel.prototype, "lastNameFirst", {
        get: function () {
            return this.lastName + ', ' + this.firstName;
        },
        enumerable: true,
        configurable: true
    });
    UserViewModel.prototype.setPristine = function () {
        this.rolesChanged = false;
    };
    UserViewModel.prototype.clone = function () {
        return new UserViewModel(this.firstName, this.lastName, this.email, this.roles.slice());
    };
    UserViewModel.prototype.removeRole = function (role) {
        this.rolesChanged = true;
        this.roles.splice(this.roles.indexOf(role), 1);
    };
    UserViewModel.prototype.addRole = function (role) {
        this.rolesChanged = true;
        this.roles.push(role);
    };
    return UserViewModel;
}());
