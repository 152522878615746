/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./frontpage.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./organizations.component.ngfactory";
import * as i4 from "./organizations.component";
import * as i5 from "../services/api.service";
import * as i6 from "../services/authorization-manager.service";
import * as i7 from "@angular/router";
import * as i8 from "../services/language.service";
import * as i9 from "./users.component.ngfactory";
import * as i10 from "./users.component";
import * as i11 from "../services/location.service";
import * as i12 from "yti-common-ui/services/user.service";
import * as i13 from "./delete-confirmation-modal.component";
import * as i14 from "@ng-bootstrap/ng-bootstrap/tabset/tabset";
import * as i15 from "./user-requests.component.ngfactory";
import * as i16 from "./user-requests.component";
import * as i17 from "yti-common-ui/components/error-modal.component";
import * as i18 from "../../../node_modules/@ng-bootstrap/ng-bootstrap/tabset/tabset.ngfactory";
import * as i19 from "@ng-bootstrap/ng-bootstrap/tabset/tabset-config";
import * as i20 from "@angular/common";
import * as i21 from "./frontpage.component";
var styles_FrontpageComponent = [i0.styles];
var RenderType_FrontpageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FrontpageComponent, data: {} });
export { RenderType_FrontpageComponent as RenderType_FrontpageComponent };
function View_FrontpageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["ORGANIZATIONS"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_FrontpageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-organizations", [], null, null, null, i3.View_OrganizationsComponent_0, i3.RenderType_OrganizationsComponent)), i1.ɵdid(1, 180224, null, 0, i4.OrganizationsComponent, [i5.ApiService, i6.AuthorizationManager, i7.Router, i8.LanguageService], null, null)], null, null); }
function View_FrontpageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["USERS"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_FrontpageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-users", [], null, null, null, i9.View_UsersComponent_0, i9.RenderType_UsersComponent)), i1.ɵdid(1, 49152, null, 0, i10.UsersComponent, [i5.ApiService, i11.LocationService, i8.LanguageService, i2.TranslateService, i12.UserService, i6.AuthorizationManager, i13.DeleteConfirmationModalService], null, null)], null, null); }
function View_FrontpageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ngb-tab", [["id", "users_tab"]], null, null, null, null, null)), i1.ɵdid(1, 2113536, [[1, 4]], 2, i14.NgbTab, [], { id: [0, "id"] }, null), i1.ɵqud(603979776, 4, { titleTpls: 1 }), i1.ɵqud(603979776, 5, { contentTpls: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_FrontpageComponent_4)), i1.ɵdid(5, 16384, [[4, 4]], 0, i14.NgbTabTitle, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_FrontpageComponent_5)), i1.ɵdid(7, 16384, [[5, 4]], 0, i14.NgbTabContent, [i1.TemplateRef], null, null)], function (_ck, _v) { var currVal_0 = "users_tab"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FrontpageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "content-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-user-requests", [], null, null, null, i15.View_UserRequestsComponent_0, i15.RenderType_UserRequestsComponent)), i1.ɵdid(2, 49152, null, 0, i16.UserRequestsComponent, [i5.ApiService, i17.ErrorModalService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 12, "ngb-tabset", [], null, null, null, i18.View_NgbTabset_0, i18.RenderType_NgbTabset)), i1.ɵdid(4, 2146304, null, 1, i14.NgbTabset, [i19.NgbTabsetConfig], null, null), i1.ɵqud(603979776, 1, { tabs: 1 }), (_l()(), i1.ɵeld(6, 0, null, null, 7, "ngb-tab", [["id", "organizations_tab"]], null, null, null, null, null)), i1.ɵdid(7, 2113536, [[1, 4]], 2, i14.NgbTab, [], { id: [0, "id"] }, null), i1.ɵqud(603979776, 2, { titleTpls: 1 }), i1.ɵqud(603979776, 3, { contentTpls: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_FrontpageComponent_1)), i1.ɵdid(11, 16384, [[2, 4]], 0, i14.NgbTabTitle, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_FrontpageComponent_2)), i1.ɵdid(13, 16384, [[3, 4]], 0, i14.NgbTabContent, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FrontpageComponent_3)), i1.ɵdid(15, 16384, null, 0, i20.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "organizations_tab"; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.canBrowseUsers(); _ck(_v, 15, 0, currVal_1); }, null); }
export function View_FrontpageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-frontpage", [], null, null, null, View_FrontpageComponent_0, RenderType_FrontpageComponent)), i1.ɵdid(1, 49152, null, 0, i21.FrontpageComponent, [i11.LocationService, i6.AuthorizationManager], null, null)], null, null); }
var FrontpageComponentNgFactory = i1.ɵccf("app-frontpage", i21.FrontpageComponent, View_FrontpageComponent_Host_0, {}, {}, []);
export { FrontpageComponentNgFactory as FrontpageComponentNgFactory };
