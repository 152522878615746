import { LanguageService } from '../../services/language.service';
import { UserService } from 'yti-common-ui/services/user.service';
import { LoginModalService } from 'yti-common-ui/components/login-modal.component';
import { ApiService } from '../../services/api.service';
import { ConfigurationService } from "../../services/configuration.service";
var NavigationBarComponent = /** @class */ (function () {
    function NavigationBarComponent(languageService, userService, loginModal, apiService, configService) {
        var _this = this;
        this.languageService = languageService;
        this.userService = userService;
        this.loginModal = loginModal;
        this.apiService = apiService;
        this.configService = configService;
        this.availableLanguages = [
            { code: 'fi', name: 'Suomeksi (FI)' },
            { code: 'sv', name: 'På svenska (SV)' },
            { code: 'en', name: 'In English (EN)' }
        ];
        this.fakeableUsers = [];
        apiService.getTestUsers().subscribe(function (users) {
            if (_this.configService.fakeLoginAllowed) {
                _this.fakeableUsers = users.map(function (u) { return ({ email: u.email, firstName: u.firstName, lastName: u.lastName }); });
            }
        });
    }
    NavigationBarComponent.prototype.fakeUser = function (userEmail) {
        this.userService.updateLoggedInUser(userEmail);
    };
    Object.defineProperty(NavigationBarComponent.prototype, "language", {
        get: function () {
            return this.languageService.language;
        },
        set: function (language) {
            this.languageService.language = language;
        },
        enumerable: true,
        configurable: true
    });
    NavigationBarComponent.prototype.logIn = function () {
        this.loginModal.open();
    };
    NavigationBarComponent.prototype.logOut = function () {
        this.userService.logout();
    };
    Object.defineProperty(NavigationBarComponent.prototype, "user", {
        get: function () {
            return this.userService.user;
        },
        enumerable: true,
        configurable: true
    });
    NavigationBarComponent.prototype.isLoggedIn = function () {
        return this.userService.isLoggedIn();
    };
    Object.defineProperty(NavigationBarComponent.prototype, "environmentIdentifier", {
        get: function () {
            var env = this.configService.env;
            return env ? env !== 'prod' ? ' - ' + env.toUpperCase() : '' : '';
        },
        enumerable: true,
        configurable: true
    });
    return NavigationBarComponent;
}());
export { NavigationBarComponent };
