import { AfterViewInit, ElementRef, OnInit, Renderer } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { ApiService } from '../services/api.service';
import { ModalService } from 'yti-common-ui/services/modal.service';
import { map } from 'rxjs/operators';
var SearchUserModalService = /** @class */ (function () {
    function SearchUserModalService(modalService) {
        this.modalService = modalService;
    }
    SearchUserModalService.prototype.open = function (excludedUserEmails) {
        var modalRef = this.modalService.open(SearchUserModalComponent, { size: 'sm' });
        var instance = modalRef.componentInstance;
        instance.excludedUserEmails = excludedUserEmails;
        return modalRef.result;
    };
    return SearchUserModalService;
}());
export { SearchUserModalService };
var SearchUserModalComponent = /** @class */ (function () {
    function SearchUserModalComponent(modal, apiService, renderer) {
        this.modal = modal;
        this.apiService = apiService;
        this.renderer = renderer;
        this.search$ = new BehaviorSubject('');
    }
    SearchUserModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchResults$ =
            combineLatest(this.search$, this.apiService.getUsers()).pipe(map(function (_a) {
                var search = _a[0], users = _a[1];
                var isUserAddable = function (user) {
                    return !_this.isExcluded(user) && (!search || user.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                        || user.email.toLowerCase().indexOf(_this.search.toLowerCase()) !== -1);
                };
                return users.filter(function (user) { return isUserAddable(user); });
            }));
    };
    SearchUserModalComponent.prototype.isExcluded = function (user) {
        for (var _i = 0, _a = this.excludedUserEmails; _i < _a.length; _i++) {
            var excludedEmail = _a[_i];
            if (user.email === excludedEmail) {
                return true;
            }
        }
        return false;
    };
    SearchUserModalComponent.prototype.selectUser = function (user) {
        this.modal.close(user);
    };
    SearchUserModalComponent.prototype.ngAfterViewInit = function () {
        this.renderer.invokeElementMethod(this.searchInput.nativeElement, 'focus');
    };
    Object.defineProperty(SearchUserModalComponent.prototype, "search", {
        get: function () {
            return this.search$.getValue();
        },
        set: function (value) {
            this.search$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    SearchUserModalComponent.prototype.cancel = function () {
        this.modal.dismiss('cancel');
    };
    return SearchUserModalComponent;
}());
export { SearchUserModalComponent };
