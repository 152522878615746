
    <div class="content-box" *ngIf="organization">

      <app-back-button id="app_back_button" (back)="back()"></app-back-button>

      <div class="clearfix">
        <h1 class="float-left" translate>Organization</h1>

        <button class="btn btn-action float-right" id="edit_organization_button" (click)="startEditing()"
                *ngIf="!editing && !notificationVisible && canEditOrganization()">
          <span translate>Edit organization</span>
        </button>

        <button class="btn btn-action float-right add-child-organization" id="add_child_organization_button" (click)="addChildOrganization(organizationId)"
                *ngIf="!editing && !notificationVisible && canEditOrganization() && !organization.parentId && !organization.removed">
          <span translate>Add child organization</span>
        </button>

        <button type="button"
                id="save_organization_button"
                *ngIf="editing || notificationVisible"
                [disabled]="!hasChanges() || !isValid() || notificationVisible"
                appNotification
                #notification="notification"
                class="btn btn-action float-right"
                (click)="saveOrganization()">{{'Save' | translate}}
        </button>

        <button type="submit"
                id="cancel_editing_button"
                class="btn btn-link cancel float-right"
                *ngIf="editing || notificationVisible"
                (click)="cancelEditing()">{{'Cancel' | translate}}
        </button>

      </div>

      <app-organization-details #details="details"
                                [organization]="organization"
                                [editing]="editing"></app-organization-details>

      <h3 translate>Users</h3>

      <p *ngIf="users.length === 0" translate>No users yet</p>
      <table *ngIf="users.length > 0">
        <thead>
        <tr>
          <th translate>Name</th>
          <th translate>Email</th>
          <th class="rotate" *ngFor="let role of availableRoles">
            <div><span>{{role | translate}}</span></div>
          </th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let user of users">
          <td>{{user.lastNameFirst}}</td>
          <td>{{user.email}}</td>
          <td *ngFor="let role of availableRoles" class="check">
            <input type="checkbox"
                   id="{{role + '_user_role_checkbox'}}"
                   [checked]="user.isInRole(role)"
                   [disabled]="!editing || isRoleDisabledForUser(user, role)"
                   (click)="user.toggleRole(role)"/>
          </td>
          <td>
            <button class="btn btn-link btn-sm"
                    id="{{user.email + '_remove_user_button'}}"
                    (click)="removeUser(user)"
                    *ngIf="canRemove(user)">
              <span class="fa fa-trash"></span>
              <span translate>Remove</span>
            </button>
          </td>
        </tr>
        </tbody>
      </table>

      <div *ngIf="editing" class="actions">
        <button type="button"
                id="add_user_button"
                class="btn btn-action"
                (click)="addUser()">{{'Add user' | translate}}
        </button>
      </div>

      <div *ngIf="!organization.parentId">
        <h3 translate>Child organizations</h3>
        <ul class="childOrganizations">
          <li *ngFor="let child of organization.childOrganizations">
            <a *ngIf="canEditChildOrganization(child.id)" (click)="viewChildOrganization(child.id)">
              {{child.name[translateService.currentLang] || child.name['fi']}}
            </a>
            <span *ngIf="!canEditChildOrganization(child.id)">{{child.name[translateService.currentLang] || child.name['fi']}}</span>
          </li>
        </ul>
      </div>
    </div>
  