import { OnDestroy } from '@angular/core';
import { UserService } from 'yti-common-ui/services/user.service';
import { Router } from '@angular/router';
import { index } from 'yti-common-ui/utils/array';
import { comparingLocalizable } from 'yti-common-ui/utils/comparator';
import { LanguageService } from '../../services/language.service';
import { LocationService } from '../../services/location.service';
import { ApiService } from '../../services/api.service';
var UserDetailsInformationComponent = /** @class */ (function () {
    function UserDetailsInformationComponent(router, userService, locationService, languageService, apiService) {
        var _this = this;
        this.router = router;
        this.userService = userService;
        this.locationService = locationService;
        this.languageService = languageService;
        this.apiService = apiService;
        this.subscriptionToClean = [];
        this.token = undefined;
        this.tokenDeleted = false;
        apiService.getOrganizationList().subscribe(function (organizations) {
            _this.allOrganizationsById = index(organizations, function (org) { return org.id; });
        });
        this.subscriptionToClean.push(this.userService.loggedIn$.subscribe(function (loggedIn) {
            if (!loggedIn) {
                router.navigate(['/']);
            }
        }));
        userService.updateLoggedInUser();
        locationService.atUserDetails();
    }
    UserDetailsInformationComponent.prototype.ngOnDestroy = function () {
        this.subscriptionToClean.forEach(function (s) { return s.unsubscribe(); });
    };
    Object.defineProperty(UserDetailsInformationComponent.prototype, "user", {
        get: function () {
            return this.userService.user;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserDetailsInformationComponent.prototype, "loading", {
        get: function () {
            return !this.allOrganizationsById;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserDetailsInformationComponent.prototype, "userOrganizations", {
        get: function () {
            var _this = this;
            var result = Array.from(this.user.rolesInOrganizations.entries()).map(function (_a) {
                var organizationId = _a[0], roles = _a[1];
                return {
                    organization: _this.allOrganizationsById.get(organizationId),
                    roles: Array.from(roles)
                };
            });
            result.sort(comparingLocalizable(this.languageService, function (org) { return org.organization ? org.organization.name : {}; }));
            return result;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserDetailsInformationComponent.prototype, "hasExistingToken", {
        get: function () {
            return this.userService.user.hasToken && this.token === undefined && !this.tokenDeleted;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserDetailsInformationComponent.prototype, "hasToken", {
        get: function () {
            return this.token !== undefined || this.hasExistingToken;
        },
        enumerable: true,
        configurable: true
    });
    UserDetailsInformationComponent.prototype.createToken = function () {
        var _this = this;
        this.apiService.createToken().subscribe(function (token) {
            if (token) {
                _this.token = token.token;
            }
            else {
                _this.token = undefined;
            }
        });
    };
    UserDetailsInformationComponent.prototype.deleteToken = function () {
        var _this = this;
        this.apiService.deleteToken().subscribe(function (boolean) {
            if (boolean) {
                _this.token = undefined;
                _this.tokenDeleted = true;
            }
        });
    };
    return UserDetailsInformationComponent;
}());
export { UserDetailsInformationComponent };
