
    <ng-template ngbModalContainer></ng-template>
    <app-navigation-bar></app-navigation-bar>
    <div class="container-fluid">
      <app-breadcrumb [location]="location"></app-breadcrumb>
      <router-outlet></router-outlet>
    </div>
    <app-footer [title]="'Interoperability platform´s user right management' | translate" 
                id="navigate_to_info_link"
                (informationClick)="navigateToInformation()"></app-footer>
  