
    <dl>
      <dt>
        <label *ngIf="label">{{label}}</label>
        <app-information-symbol [infoText]="infoText"></app-information-symbol>
      </dt>
      <dd>
        <a *ngIf="showAsLink" target="_blank" rel="noopener noreferrer" href="{{value}}">{{value}}</a>
        <span class="link" *ngIf="!showAsLink">{{value}}</span>
        <img [src]="this.clippyImage"
             class="svg-icon clipboard"
             #t="ngbTooltip"
             ngbTooltip="{{'Copy value to clipboard' | translate:translateParams}}"
             #p="ngbPopover"
             ngbPopover="{{'Copied to clipboard' | translate}}"
             ngxClipboard [cbContent]="value"
             (click)="clickToolTip()">
      </dd>
    </dl>
  