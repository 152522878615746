
    <div class="modal-header">
      <h4 class="modal-title">
        <a><i class="fa fa-times" id="search_modal_cancel_link" (click)="cancel()"></i></a>
        <span translate>Select user</span>
      </h4>
    </div>

    <div class="modal-body full-height">
      <div class="row mb-2">
        <div class="col-12">
          <div class="input-group input-group-lg input-group-search">
            <input #searchInput
                   id="search_user_input"
                   type="text"
                   class="form-control"
                   placeholder="{{'Search user' | translate}}"
                   [(ngModel)]="search"/>
          </div>
        </div>
      </div>

      <div class="row full-height">
        <div class="col-12">
          <div class="content-box">
            <div class="search-results">
              <div *ngFor="let user of searchResults$ | async; let last = last"
                   class="search-result"
                   id="{{'search_results_user_' + user.email}}"
                   (click)="selectUser(user)">

                <div class="content" [class.last]="last">
                  <span class="title">{{user.name}}</span>
                  <span class="body">{{user.email}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">

      <button type="button"
              id="cancel_button"
              class="btn btn-link cancel"
              (click)="cancel()">{{'Cancel' | translate}}
      </button>

    </div>
  