import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var ConfigurationService = /** @class */ (function () {
    function ConfigurationService(apiService) {
        this.apiService = apiService;
    }
    ConfigurationService.prototype.fetchConfiguration = function () {
        var _this = this;
        return this.apiService.getConfiguration().toPromise().then(function (configuration) {
            _this.configuration = configuration;
            return configuration;
        });
    };
    Object.defineProperty(ConfigurationService.prototype, "loading", {
        get: function () {
            return this.configuration == null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "env", {
        get: function () {
            return this.configuration.env;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "isMessagingEnabled", {
        get: function () {
            return this.configuration.messagingEnabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "terminologyUrl", {
        get: function () {
            return this.configuration.terminologyUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "dataModelUrl", {
        get: function () {
            return this.configuration.dataModelUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "codeListUrl", {
        get: function () {
            return this.configuration.codeListUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "commentsUrl", {
        get: function () {
            return this.configuration.commentsUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "fakeLoginAllowed", {
        get: function () {
            return this.configuration.fakeLoginAllowed;
        },
        enumerable: true,
        configurable: true
    });
    ConfigurationService.prototype.getUriWithEnv = function (uri) {
        if (uri && this.env !== 'prod') {
            return uri + '?env=' + this.env;
        }
        return uri ? uri : null;
    };
    ConfigurationService.prototype.getEnvironmentIdentifier = function (style) {
        if (this.env !== 'prod') {
            var identifier = this.env.toUpperCase();
            if (!style) {
                return identifier;
            }
            else if (style === 'prefix') {
                return identifier + ' - ';
            }
            else if (style === 'postfix') {
                return ' - ' + identifier;
            }
        }
        return '';
    };
    ConfigurationService.ngInjectableDef = i0.defineInjectable({ factory: function ConfigurationService_Factory() { return new ConfigurationService(i0.inject(i1.ApiService)); }, token: ConfigurationService, providedIn: "root" });
    return ConfigurationService;
}());
export { ConfigurationService };
