/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./navigation/navigation-bar.component.ngfactory";
import * as i3 from "./navigation/navigation-bar.component";
import * as i4 from "../services/language.service";
import * as i5 from "yti-common-ui/services/user.service";
import * as i6 from "yti-common-ui/components/login-modal.component";
import * as i7 from "../services/api.service";
import * as i8 from "../services/configuration.service";
import * as i9 from "../../../node_modules/yti-common-ui/components/breadcrumb.component.ngfactory";
import * as i10 from "yti-common-ui/components/breadcrumb.component";
import * as i11 from "@angular/router";
import * as i12 from "../../../node_modules/yti-common-ui/components/footer.component.ngfactory";
import * as i13 from "yti-common-ui/components/footer.component";
import * as i14 from "@ngx-translate/core";
import * as i15 from "./app.component";
import * as i16 from "../services/location.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0, null, View_AppComponent_1)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-navigation-bar", [], null, null, null, i2.View_NavigationBarComponent_0, i2.RenderType_NavigationBarComponent)), i1.ɵdid(2, 49152, null, 0, i3.NavigationBarComponent, [i4.LanguageService, i5.UserService, i6.LoginModalService, i7.ApiService, i8.ConfigurationService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-breadcrumb", [], null, null, null, i9.View_BreadcrumbComponent_0, i9.RenderType_BreadcrumbComponent)), i1.ɵdid(5, 49152, null, 0, i10.BreadcrumbComponent, [i11.Router], { location: [0, "location"] }, null), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i11.RouterOutlet, [i11.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "app-footer", [["id", "navigate_to_info_link"]], null, [[null, "informationClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("informationClick" === en)) {
        var pd_0 = (_co.navigateToInformation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_FooterComponent_0, i12.RenderType_FooterComponent)), i1.ɵdid(9, 49152, null, 0, i13.FooterComponent, [i14.TranslateService], { title: [0, "title"] }, { informationClick: "informationClick" }), i1.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.location; _ck(_v, 5, 0, currVal_0); _ck(_v, 7, 0); var currVal_1 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("Interoperability platform\u00B4s user right management")); _ck(_v, 9, 0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i15.AppComponent, [i16.LocationService, i11.Router], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i15.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
