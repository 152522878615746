
    <app-ajax-loading-indicator *ngIf="loading"></app-ajax-loading-indicator>

    <h1 translate>Users</h1>

    <div class="top-actions">

      <div class="input-group input-group-search float-left">
        <input #searchInput
               id="search_user_input"
               type="text"
               class="form-control"
               placeholder="{{'Search user' | translate}}"
               [(ngModel)]="search" />
      </div>

      <app-filter-dropdown [options]="organizationOptions"
                           id="organizations_dropdown"
                           [filterSubject]="organization$"
                           class="float-left ml-2"></app-filter-dropdown>

      <app-filter-dropdown [options]="roleOptions"
                           id="roles_dropdown"
                           [filterSubject]="role$"
                           class="float-left ml-2"></app-filter-dropdown>
    </div>

    <div class="results">
      <div class="result" *ngFor="let user of users$ | async">
        <h4>{{user.displayName}} <span class="email">({{user.email}})</span>
          <button class="btn btn-link btn-sm"
                  id="{{user.email + '_remove_user_button'}}"
                  (click)="removeUser(user)"
                  *ngIf="canRemoveUser()">
            <span class="fa fa-trash"></span>
            <span translate>Remove</span>
          </button>
          <div id="time">{{user.creationDateTime | dateTime }}</div>
          <div *ngIf="user.superuser" id="superuser"><br translate>SuperUser</div>
        </h4>

        <ul>
          <li *ngFor="let organization of user.organizations">
            <a [routerLink]="['/organization', organization.id]" id="{{'organization_listitem_' + organization.id}}">
              {{organization.name | translateValue}}
            </a>:
            <span *ngFor="let role of organization.roles; let last = last">
                <span class="role" id="{{'role_item_' + role}}">{{role | translate}}</span><span [hidden]="last">,</span>
              </span>
          </li>
        </ul>
        <br>
      </div>
    </div>
  