import { Router } from '@angular/router';
import { LocationService } from '../services/location.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(locationService, router) {
        this.locationService = locationService;
        this.router = router;
    }
    Object.defineProperty(AppComponent.prototype, "location", {
        get: function () {
            return this.locationService.location;
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.navigateToInformation = function () {
        this.router.navigate(['/information']);
    };
    return AppComponent;
}());
export { AppComponent };
