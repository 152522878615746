import { NgbPopover, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
var clippyImage = require('../../../assets/clippy.svg');
var InlineClipboardComponent = /** @class */ (function () {
    function InlineClipboardComponent() {
        this.showAsLink = false;
        this.clippyImage = clippyImage;
    }
    Object.defineProperty(InlineClipboardComponent.prototype, "translateParams", {
        get: function () {
            return {
                value: this.value
            };
        },
        enumerable: true,
        configurable: true
    });
    InlineClipboardComponent.prototype.clickToolTip = function () {
        var _this = this;
        this.tooltip.close();
        setTimeout(function () {
            _this.popover.close();
        }, 1500);
    };
    return InlineClipboardComponent;
}());
export { InlineClipboardComponent };
