import { UserService } from 'yti-common-ui/services/user.service';
import { contains } from 'yti-common-ui/utils/array';
var AuthorizationManager = /** @class */ (function () {
    function AuthorizationManager(userService) {
        this.userService = userService;
    }
    Object.defineProperty(AuthorizationManager.prototype, "user", {
        get: function () {
            return this.userService.user;
        },
        enumerable: true,
        configurable: true
    });
    AuthorizationManager.prototype.canCreateOrganization = function () {
        return this.user ? this.user.superuser : false;
    };
    AuthorizationManager.prototype.canBrowseUsers = function () {
        return this.user.superuser || this.isAdminInAnyOrganization();
    };
    AuthorizationManager.prototype.canViewOrganization = function (organizationId) {
        return this.user.superuser || this.user.isInRole('ADMIN', organizationId);
    };
    AuthorizationManager.prototype.canEditOrganization = function (organizationId) {
        return this.user.superuser || this.user.isInRole('ADMIN', organizationId);
    };
    AuthorizationManager.prototype.isAdminInAnyOrganization = function () {
        return contains(Array.from(this.user.organizationsInRole.keys()), 'ADMIN');
    };
    AuthorizationManager.prototype.canRemoveOrganization = function () {
        return this.user.superuser;
    };
    AuthorizationManager.prototype.canRemoveUser = function () {
        return this.user.superuser;
    };
    return AuthorizationManager;
}());
export { AuthorizationManager };
