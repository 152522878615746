import { OnInit } from '@angular/core';
import { LanguageService } from '../../services/language.service';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from '../../services/configuration.service';
import { BehaviorSubject } from 'rxjs';
import { MessagingService } from '../../services/messaging-service';
import { UserService } from 'yti-common-ui/services/user.service';
import { ConfirmationModalService } from 'yti-common-ui/components/confirmation-modal.component';
import { ErrorModalService } from 'yti-common-ui/components/error-modal.component';
import { comparingLocalizable, comparingPrimitive } from 'yti-common-ui/utils/comparator';
var UserDetailsSubscriptionsComponent = /** @class */ (function () {
    function UserDetailsSubscriptionsComponent(languageService, messagingService, configurationService, userService, confirmationModalService, errorModalService) {
        var _this = this;
        this.languageService = languageService;
        this.messagingService = messagingService;
        this.configurationService = configurationService;
        this.userService = userService;
        this.confirmationModalService = confirmationModalService;
        this.errorModalService = errorModalService;
        this.messagingResources$ = new BehaviorSubject(null);
        this.loading = true;
        this.APPLICATION_CODELIST = 'codelist';
        this.APPLICATION_TERMINOLOGY = 'terminology';
        this.APPLICATION_DATAMODEL = 'datamodel';
        this.APPLICATION_COMMENTS = 'comments';
        this.languageService.language$.subscribe(function (language) {
            _this.sortMessagingResources();
        });
    }
    UserDetailsSubscriptionsComponent.prototype.ngOnInit = function () {
        if (this.configurationService.isMessagingEnabled && this.userService.isLoggedIn()) {
            this.getUserSubscriptionData();
        }
        else {
            this.loading = false;
        }
    };
    UserDetailsSubscriptionsComponent.prototype.getUserSubscriptionData = function () {
        var _this = this;
        this.loading = true;
        this.messagingService.getMessagingUserData().subscribe(function (messagingUserData) {
            if (messagingUserData) {
                _this.subscriptionType = messagingUserData.subscriptionType;
                var resources = new Map();
                var codelistMessagingResources_1 = [];
                var datamodelMessagingResources_1 = [];
                var terminologyMessagingResources_1 = [];
                var commentsMessagingResources_1 = [];
                messagingUserData.resources.forEach(function (resource) {
                    if (resource.application === _this.APPLICATION_CODELIST) {
                        codelistMessagingResources_1.push(resource);
                    }
                    else if (resource.application === _this.APPLICATION_DATAMODEL) {
                        datamodelMessagingResources_1.push(resource);
                    }
                    else if (resource.application === _this.APPLICATION_TERMINOLOGY) {
                        terminologyMessagingResources_1.push(resource);
                    }
                    else if (resource.application === _this.APPLICATION_COMMENTS) {
                        commentsMessagingResources_1.push(resource);
                    }
                });
                if (codelistMessagingResources_1.length > 0) {
                    resources.set(_this.APPLICATION_CODELIST, codelistMessagingResources_1);
                }
                if (datamodelMessagingResources_1.length > 0) {
                    resources.set(_this.APPLICATION_DATAMODEL, datamodelMessagingResources_1);
                }
                if (terminologyMessagingResources_1.length > 0) {
                    resources.set(_this.APPLICATION_TERMINOLOGY, terminologyMessagingResources_1);
                }
                if (commentsMessagingResources_1.length > 0) {
                    resources.set(_this.APPLICATION_COMMENTS, commentsMessagingResources_1);
                }
                if (resources.size > 0) {
                    _this.messagingResources = resources;
                    _this.sortMessagingResources();
                }
                else {
                    _this.messagingResources = null;
                }
            }
            else {
                _this.messagingResources = null;
            }
            _this.loading = false;
        });
    };
    UserDetailsSubscriptionsComponent.prototype.sortMessagingResources = function () {
        var resourceMap = this.messagingResources$.getValue();
        if (resourceMap) {
            this.sortApplicationResources(resourceMap, this.APPLICATION_TERMINOLOGY);
            this.sortApplicationResources(resourceMap, this.APPLICATION_DATAMODEL);
            this.sortApplicationResources(resourceMap, this.APPLICATION_CODELIST);
            this.sortApplicationResources(resourceMap, this.APPLICATION_COMMENTS);
        }
        this.messagingResources = resourceMap;
    };
    UserDetailsSubscriptionsComponent.prototype.sortApplicationResources = function (resourceMap, applicationIdentifier) {
        var _this = this;
        if (resourceMap.has(applicationIdentifier)) {
            // @ts-ignore
            resourceMap.get(applicationIdentifier).sort(comparingPrimitive(function (resource) { return _this.languageService.isLocalizableEmpty(resource.prefLabel); })
                .andThen(comparingPrimitive(function (resource) {
                return _this.languageService.isLocalizableEmpty(resource.prefLabel) ? resource.uri.toLowerCase() : null;
            }))
                .andThen(comparingLocalizable(this.languageService, function (resource) { return resource.prefLabel ? resource.prefLabel : {}; }, true)));
        }
    };
    Object.defineProperty(UserDetailsSubscriptionsComponent.prototype, "messagingResources", {
        get: function () {
            return this.messagingResources$.getValue();
        },
        set: function (value) {
            this.messagingResources$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    UserDetailsSubscriptionsComponent.prototype.removeSubscription = function (resource) {
        var _this = this;
        this.openRemoveSubscription()
            .then(function () {
            _this.messagingService.deleteSubscription(resource.uri).subscribe(function (success) {
                if (success) {
                    var messagingResources = _this.messagingResources;
                    if (messagingResources != null) {
                        var resources = messagingResources.get(resource.application);
                        if (resources != null) {
                            var resourceIndex = resources.indexOf(resource, 0);
                            if (resourceIndex > -1) {
                                resources.splice(resourceIndex, 1);
                            }
                            if (resources.length === 0) {
                                messagingResources.delete(resource.application);
                                _this.messagingResources = messagingResources;
                            }
                            if (messagingResources.size === 0) {
                                _this.tabSet.select('user_details_info_tab');
                            }
                        }
                    }
                }
                else {
                    _this.errorModalService.open('Submit error', 'Subscription deletion failed.', null);
                }
            });
        }, ignoreModalClose);
    };
    UserDetailsSubscriptionsComponent.prototype.openRemoveSubscription = function () {
        return this.confirmationModalService.open('REMOVE EMAIL SUBSCRIPTION TO RESOURCE?', undefined, '');
    };
    Object.defineProperty(UserDetailsSubscriptionsComponent.prototype, "isSubscriptionEnabled", {
        get: function () {
            return this.subscriptionType !== 'DISABLED';
        },
        enumerable: true,
        configurable: true
    });
    UserDetailsSubscriptionsComponent.prototype.toggleSubscription = function (event) {
        var _this = this;
        event.preventDefault();
        var subscriptionTargetType = this.subscriptionType === 'DAILY' ? 'DISABLED' : 'DAILY';
        this.openToggleNotifications(subscriptionTargetType === 'DAILY')
            .then(function () {
            _this.messagingService.setSubscriptionType(subscriptionTargetType).subscribe(function (messagingUserData) {
                _this.subscriptionType = messagingUserData.subscriptionType;
            });
        }, ignoreModalClose);
    };
    UserDetailsSubscriptionsComponent.prototype.openToggleNotifications = function (enable) {
        if (enable) {
            return this.confirmationModalService.open('ARE YOU SURE YOU WANT TO ENABLE THE NOTIFICATION EMAIL SUBSCRIPTION?', undefined, '');
        }
        else {
            return this.confirmationModalService.open('ARE YOU SURE YOU WANT TO DISABLE THE NOTIFICATION EMAIL SUBSCRIPTION?', undefined, '');
        }
    };
    UserDetailsSubscriptionsComponent.prototype.getUriWithEnv = function (uri) {
        return this.configurationService.getUriWithEnv(uri);
    };
    return UserDetailsSubscriptionsComponent;
}());
export { UserDetailsSubscriptionsComponent };
