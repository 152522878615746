/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search-user-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/common";
import * as i5 from "./search-user-modal.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import * as i7 from "../services/api.service";
var styles_SearchUserModalComponent = [i0.styles];
var RenderType_SearchUserModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchUserModalComponent, data: {} });
export { RenderType_SearchUserModalComponent as RenderType_SearchUserModalComponent };
function View_SearchUserModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "search-result"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectUser(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "content"]], [[2, "last", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", ("search_results_user_" + _v.context.$implicit.email), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.last; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.context.$implicit.email; _ck(_v, 5, 0, currVal_3); }); }
export function View_SearchUserModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { searchInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-times"], ["id", "search_modal_cancel_link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(6, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Select user"])), (_l()(), i1.ɵeld(8, 0, null, null, 17, "div", [["class", "modal-body full-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 9, "div", [["class", "row mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 7, "div", [["class", "input-group input-group-lg input-group-search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, [[1, 0], ["searchInput", 1]], null, 6, "input", [["class", "form-control"], ["id", "search_user_input"], ["type", "text"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 13)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 13)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.search = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(15, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(17, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, null, 6, "div", [["class", "row full-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 5, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 4, "div", [["class", "content-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 3, "div", [["class", "search-results"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SearchUserModalComponent_1)), i1.ɵdid(24, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(26, 0, null, null, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 2, "button", [["class", "btn btn-link cancel"], ["id", "cancel_button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(28, null, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 6, 0, currVal_0); var currVal_9 = _co.search; _ck(_v, 15, 0, currVal_9); var currVal_10 = i1.ɵunv(_v, 24, 0, i1.ɵnov(_v, 25).transform(_co.searchResults$)); _ck(_v, 24, 0, currVal_10); }, function (_ck, _v) { var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 18).transform("Search user")), ""); var currVal_2 = i1.ɵnov(_v, 17).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 17).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 17).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 17).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 17).ngClassValid; var currVal_7 = i1.ɵnov(_v, 17).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 17).ngClassPending; _ck(_v, 12, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_11 = i1.ɵunv(_v, 28, 0, i1.ɵnov(_v, 29).transform("Cancel")); _ck(_v, 28, 0, currVal_11); }); }
export function View_SearchUserModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-search-modal", [], null, null, null, View_SearchUserModalComponent_0, RenderType_SearchUserModalComponent)), i1.ɵdid(1, 4308992, null, 0, i5.SearchUserModalComponent, [i6.NgbActiveModal, i7.ApiService, i1.Renderer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchUserModalComponentNgFactory = i1.ɵccf("app-search-modal", i5.SearchUserModalComponent, View_SearchUserModalComponent_Host_0, { excludedUserEmails: "excludedUserEmails" }, {}, []);
export { SearchUserModalComponentNgFactory as SearchUserModalComponentNgFactory };
