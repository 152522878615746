
    <div class="content-box">

      <app-user-requests></app-user-requests>
      
      <ngb-tabset>
        <ngb-tab id="organizations_tab">
          <ng-template ngbTabTitle>
            <span translate>ORGANIZATIONS</span>
          </ng-template>

          <ng-template ngbTabContent>
            <app-organizations></app-organizations>
          </ng-template>
        </ngb-tab>

        <ngb-tab id="users_tab" *ngIf="canBrowseUsers()">
          <ng-template ngbTabTitle>
            <span translate>USERS</span>
          </ng-template>

          <ng-template ngbTabContent>
            <app-users></app-users>
          </ng-template>
        </ngb-tab>
        
      </ngb-tabset>
      
    </div>
  