/* tslint:disable */
import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
export interface Dictionary<V> {
    [key: string]: V;
}

export type LocalDateTime = {};
export type UUID = {};

export type Role = "ADMIN" | "DATA_MODEL_EDITOR" | "TERMINOLOGY_EDITOR" | "CODE_LIST_EDITOR" | "MEMBER";

export class ConfigurationModel {
    codeListUrl: string;
    commentsUrl: string;
    dataModelUrl: string;
    dev: boolean;
    env: string;
    fakeLoginAllowed: boolean;
    impersonateAllowed: boolean;
    messagingEnabled: boolean;
    terminologyUrl: string;
}

export class CreateOrganization {
    adminUserEmails: string[];
    descriptionEn: string;
    descriptionFi: string;
    descriptionSv: string;
    nameEn: string;
    nameFi: string;
    nameSv: string;
    parentId: UUID;
    url: string;
}

export class EmailRole {
    role: string;
    userEmail: string;
}

export class NewUser {
    email: string;
    firstName: string;
    id: UUID;
    lastName: string;
}

export class Organization {
    descriptionEn: string;
    descriptionFi: string;
    descriptionSv: string;
    id: UUID;
    nameEn: string;
    nameFi: string;
    nameSv: string;
    parentId: UUID;
    removed: boolean;
    url: string;
}

export class OrganizationListItem {
    id: UUID;
    name: Dictionary<string>;
}

export class OrganizationRoles {
    id: UUID;
    roles: string[];
}

export class OrganizationWithUsers {
    availableRoles: string[];
    childOrganizations: OrganizationListItem[];
    organization: Organization;
    users: UserWithRoles[];
}

export class PrivateApiTempUserListItem {
    containerUri: string;
    email: string;
    firstName: string;
    id: UUID;
    lastName: string;
    tokenRole: string;
}

export class PublicApiOrganization {
    description: Dictionary<string>;
    parentId: UUID;
    prefLabel: Dictionary<string>;
    removed: boolean;
    url: string;
    uuid: UUID;
}

export class PublicApiUser {
    containerUri: string;
    creationDateTime: LocalDateTime;
    email: string;
    firstName: string;
    id: UUID;
    lastName: string;
    newlyCreated: boolean;
    organization: PublicApiUserOrganization[];
    removalDateTime: LocalDateTime;
    superuser: boolean;
    tokenCreatedAt: LocalDateTime;
    tokenInvalidationAt: LocalDateTime;
    tokenRole: string;
}

export class PublicApiUserListItem {
    email: string;
    firstName: string;
    id: UUID;
    lastName: string;
}

export class PublicApiUserOrganization {
    role: string[];
    uuid: UUID;
}

export class PublicApiUserRequest {
    organizationId: UUID;
    role: string[];
}

export class TempUser {
    containerUri: string;
    creationDateTime: string;
    email: string;
    firstName: string;
    id: UUID;
    lastName: string;
    tokenCreatedAt: string;
    tokenInvalidationAt: string;
    tokenRole: string;
}

export class TokenModel {
    token: string;
}

export class UpdateOrganization {
    organization: Organization;
    userRoles: EmailRole[];
}

export class User {
    creationDateTime: string;
    email: string;
    firstName: string;
    id: UUID;
    lastName: string;
    superuser: boolean;
    tokenCreatedAt: string;
    tokenInvalidationAt: string;
}

export class UserRequestModel {
    email: string;
    organizationId: UUID;
    role: string;
}

export class UserRequestWithOrganization {
    email: string;
    firstName: string;
    fullName: string;
    id: number;
    lastName: string;
    organizationId: UUID;
    organizationName: Dictionary<string>;
    role: string;
    sent: boolean;
}

export class UserWithRoles {
    roles: string[];
    user: User;
}

export class UserWithRolesInOrganizations {
    creationDateTime: LocalDateTime;
    email: string;
    firstName: string;
    lastName: string;
    organizations: OrganizationRoles[];
    removalDateTime: LocalDateTime;
    superuser: boolean;
    userId: UUID;
}

export class VersionInformation {
    branch: string;
    commitId: string;
}

export class YtiUser {
    accountNonExpired: boolean;
    accountNonLocked: boolean;
    anonymous: boolean;
    authorities: any[];
    containerUri: string;
    credentialsNonExpired: boolean;
    email: string;
    enabled: boolean;
    firstName: string;
    id: UUID;
    lastName: string;
    newlyCreated: boolean;
    organizationsInRole: Dictionary<any>;
    password: string;
    rolesInOrganizations: Dictionary<any>;
    superuser: boolean;
    tokenCreatedAt: LocalDateTime;
    tokenInvalidationAt: LocalDateTime;
    tokenRole: string;
    username: string;
}

export function registerDefaultSerializers(config: ApinaConfig) {
    config.registerIdentitySerializer('LocalDateTime');
    config.registerIdentitySerializer('UUID');

    config.registerIdentitySerializer('Role');

    config.registerClassSerializer('ConfigurationModel', {
        'codeListUrl': 'string',
        'commentsUrl': 'string',
        'dataModelUrl': 'string',
        'dev': 'boolean',
        'env': 'string',
        'fakeLoginAllowed': 'boolean',
        'impersonateAllowed': 'boolean',
        'messagingEnabled': 'boolean',
        'terminologyUrl': 'string'
    });

    config.registerClassSerializer('CreateOrganization', {
        'adminUserEmails': 'string[]',
        'descriptionEn': 'string',
        'descriptionFi': 'string',
        'descriptionSv': 'string',
        'nameEn': 'string',
        'nameFi': 'string',
        'nameSv': 'string',
        'parentId': 'UUID',
        'url': 'string'
    });

    config.registerClassSerializer('EmailRole', {
        'role': 'string',
        'userEmail': 'string'
    });

    config.registerClassSerializer('NewUser', {
        'email': 'string',
        'firstName': 'string',
        'id': 'UUID',
        'lastName': 'string'
    });

    config.registerClassSerializer('Organization', {
        'descriptionEn': 'string',
        'descriptionFi': 'string',
        'descriptionSv': 'string',
        'id': 'UUID',
        'nameEn': 'string',
        'nameFi': 'string',
        'nameSv': 'string',
        'parentId': 'UUID',
        'removed': 'boolean',
        'url': 'string'
    });

    config.registerClassSerializer('OrganizationListItem', {
        'id': 'UUID',
        'name': 'Dictionary<string>'
    });

    config.registerClassSerializer('OrganizationRoles', {
        'id': 'UUID',
        'roles': 'string[]'
    });

    config.registerClassSerializer('OrganizationWithUsers', {
        'availableRoles': 'string[]',
        'childOrganizations': 'OrganizationListItem[]',
        'organization': 'Organization',
        'users': 'UserWithRoles[]'
    });

    config.registerClassSerializer('PrivateApiTempUserListItem', {
        'containerUri': 'string',
        'email': 'string',
        'firstName': 'string',
        'id': 'UUID',
        'lastName': 'string',
        'tokenRole': 'string'
    });

    config.registerClassSerializer('PublicApiOrganization', {
        'description': 'Dictionary<string>',
        'parentId': 'UUID',
        'prefLabel': 'Dictionary<string>',
        'removed': 'boolean',
        'url': 'string',
        'uuid': 'UUID'
    });

    config.registerClassSerializer('PublicApiUser', {
        'containerUri': 'string',
        'creationDateTime': 'LocalDateTime',
        'email': 'string',
        'firstName': 'string',
        'id': 'UUID',
        'lastName': 'string',
        'newlyCreated': 'boolean',
        'organization': 'PublicApiUserOrganization[]',
        'removalDateTime': 'LocalDateTime',
        'superuser': 'boolean',
        'tokenCreatedAt': 'LocalDateTime',
        'tokenInvalidationAt': 'LocalDateTime',
        'tokenRole': 'string'
    });

    config.registerClassSerializer('PublicApiUserListItem', {
        'email': 'string',
        'firstName': 'string',
        'id': 'UUID',
        'lastName': 'string'
    });

    config.registerClassSerializer('PublicApiUserOrganization', {
        'role': 'string[]',
        'uuid': 'UUID'
    });

    config.registerClassSerializer('PublicApiUserRequest', {
        'organizationId': 'UUID',
        'role': 'string[]'
    });

    config.registerClassSerializer('TempUser', {
        'containerUri': 'string',
        'creationDateTime': 'string',
        'email': 'string',
        'firstName': 'string',
        'id': 'UUID',
        'lastName': 'string',
        'tokenCreatedAt': 'string',
        'tokenInvalidationAt': 'string',
        'tokenRole': 'string'
    });

    config.registerClassSerializer('TokenModel', {
        'token': 'string'
    });

    config.registerClassSerializer('UpdateOrganization', {
        'organization': 'Organization',
        'userRoles': 'EmailRole[]'
    });

    config.registerClassSerializer('User', {
        'creationDateTime': 'string',
        'email': 'string',
        'firstName': 'string',
        'id': 'UUID',
        'lastName': 'string',
        'superuser': 'boolean',
        'tokenCreatedAt': 'string',
        'tokenInvalidationAt': 'string'
    });

    config.registerClassSerializer('UserRequestModel', {
        'email': 'string',
        'organizationId': 'UUID',
        'role': 'string'
    });

    config.registerClassSerializer('UserRequestWithOrganization', {
        'email': 'string',
        'firstName': 'string',
        'fullName': 'string',
        'id': 'number',
        'lastName': 'string',
        'organizationId': 'UUID',
        'organizationName': 'Dictionary<string>',
        'role': 'string',
        'sent': 'boolean'
    });

    config.registerClassSerializer('UserWithRoles', {
        'roles': 'string[]',
        'user': 'User'
    });

    config.registerClassSerializer('UserWithRolesInOrganizations', {
        'creationDateTime': 'LocalDateTime',
        'email': 'string',
        'firstName': 'string',
        'lastName': 'string',
        'organizations': 'OrganizationRoles[]',
        'removalDateTime': 'LocalDateTime',
        'superuser': 'boolean',
        'userId': 'UUID'
    });

    config.registerClassSerializer('VersionInformation', {
        'branch': 'string',
        'commitId': 'string'
    });

    config.registerClassSerializer('YtiUser', {
        'accountNonExpired': 'boolean',
        'accountNonLocked': 'boolean',
        'anonymous': 'boolean',
        'authorities': 'any[]',
        'containerUri': 'string',
        'credentialsNonExpired': 'boolean',
        'email': 'string',
        'enabled': 'boolean',
        'firstName': 'string',
        'id': 'UUID',
        'lastName': 'string',
        'newlyCreated': 'boolean',
        'organizationsInRole': 'Dictionary<any>',
        'password': 'string',
        'rolesInOrganizations': 'Dictionary<any>',
        'superuser': 'boolean',
        'tokenCreatedAt': 'LocalDateTime',
        'tokenInvalidationAt': 'LocalDateTime',
        'tokenRole': 'string',
        'username': 'string'
    });

}

export class ApinaConfig {

    /** Prefix added for all API calls */
    baseUrl: string = "";

    private serializers: SerializerMap = {
        any: identitySerializer,
        string: identitySerializer,
        number: identitySerializer,
        boolean: identitySerializer
    };

    constructor() {
        registerDefaultSerializers(this);
    }

    serialize(value: any, type: string): any {
        return this.lookupSerializer(type).serialize(value);
    }

    deserialize(value: any, type: string): any {
        return this.lookupSerializer(type).deserialize(value);
    }

    registerSerializer(name: string, serializer: Serializer) {
        this.serializers[name] = serializer;
    }

    registerEnumSerializer(name: string, enumObject: any) {
        this.registerSerializer(name, enumSerializer(enumObject));
    }

    registerClassSerializer(name: string, fields: any) {
        this.registerSerializer(name, this.classSerializer(fields));
    }

    registerIdentitySerializer(name: string) {
        this.registerSerializer(name, identitySerializer);
    }

    registerDiscriminatedUnionSerializer(name: string, discriminator: string, types: { [key: string]: string; }) {
        this.registerSerializer(name, this.discriminatedUnionSerializer(discriminator, types));
    }

    private classSerializer(fields: any): Serializer {
        function mapProperties(obj: any, propertyMapper: (value: any, type: string) => any) {
            if (obj === null || obj === undefined) {
                return obj;
            }

            const result: any = {};

            for (const name in fields) {
                if (fields.hasOwnProperty(name)) {
                    const value: any = obj[name];
                    const type: string = fields[name];
                    result[name] = propertyMapper(value, type);
                }
            }

            return result;
        }

        const serialize = this.serialize.bind(this);
        const deserialize = this.deserialize.bind(this);
        return {
            serialize(obj) {
                return mapProperties(obj, serialize);
            },
            deserialize(obj) {
                return mapProperties(obj, deserialize);
            }
        };
    }

    private discriminatedUnionSerializer(discriminatorProperty: string, types: { [key: string]: string; }): Serializer {
        const resolveSerializer = (localType: string) => {
            return this.lookupSerializer(types[localType]);
        };

        return {
            serialize(obj) {
                if (obj == null) return null;

                const localType = obj[discriminatorProperty];
                const result = resolveSerializer(localType).serialize(obj);
                result[discriminatorProperty] = localType;
                return result;
            },
            deserialize(obj) {
                if (obj == null) return null;

                const localType = obj[discriminatorProperty];
                const result = resolveSerializer(localType).deserialize(obj);
                result[discriminatorProperty] = localType;
                return result;
            }
        };
    }

    private lookupSerializer(type: string): Serializer {
        if (!type) throw new Error("no type given");

        if (type.indexOf('[]', type.length - 2) !== -1) { // type.endsWith('[]')
            const elementType = type.substring(0, type.length - 2);
            const elementSerializer = this.lookupSerializer(elementType);
            return arraySerializer(elementSerializer);
        }

        const dictionaryMatched = /^Dictionary<(.+)>$/.exec(type);
        if (dictionaryMatched) {
            return dictionarySerializer(this.lookupSerializer(dictionaryMatched[1]));
        }

        const serializer = this.serializers[type];
        if (serializer) {
            return serializer;
        } else {
            throw new Error(`could not find serializer for type '${type}'`);
        }
    }
}

function arraySerializer(elementSerializer: Serializer): Serializer {
    function safeMap(value: any[], mapper: (a: any) => any) {
        if (!value)
            return value;
        else
            return value.map(mapper);
    }

    const serialize = elementSerializer.serialize.bind(elementSerializer);
    const deserialize = elementSerializer.deserialize.bind(elementSerializer);

    return {
        serialize(value) {
            return safeMap(value, serialize);
        },
        deserialize(value) {
            return safeMap(value, deserialize);
        }
    }
}

function dictionarySerializer(valueSerializer: Serializer): Serializer {
    function safeMap(dictionary: Dictionary<any>, mapper: (a: any) => any) {
        if (!dictionary)
            return dictionary;
        else {
            const result: any = {};
            for (const key in dictionary) {
                if (dictionary.hasOwnProperty(key)) {
                    result[key] = mapper(dictionary[key])
                }
            }
            return result
        }
    }

    const serialize = valueSerializer.serialize.bind(valueSerializer);
    const deserialize = valueSerializer.deserialize.bind(valueSerializer);

    return {
        serialize(value) {
            return safeMap(value, serialize);
        },
        deserialize(value) {
            return safeMap(value, deserialize);
        }
    }
}

function formatQueryParameters(params: { [key: string]: any }): string {
    const queryParameters: string[] = [];

    const addQueryParameter = (encodedKey: string, value: any) => {
        if (value != null)
            queryParameters.push(`${encodedKey}=${encodeURIComponent(value)}`);
    };

    for (const key of Object.keys(params || {})) {
        const value = params[key];
        const encodedKey = encodeURIComponent(key);

        if (Array.isArray(value)) {
            for (const arrayItemValue of value)
                addQueryParameter(encodedKey, arrayItemValue);
        } else {
            addQueryParameter(encodedKey, value);
        }
    }

    return queryParameters.length > 0 ? '?' + queryParameters.join('&') : '';
}

export interface UrlData {
    uriTemplate: string;
    pathVariables?: any;
    requestParams?: any;
}

export interface RequestData extends UrlData {
    method: string;
    requestBody?: any;
    responseType?: string;
}

export interface Serializer {
    serialize(o: any): any;
    deserialize(o: any): any;
}

const identitySerializer: Serializer = {
    serialize(o) {
        return o;
    },
    deserialize(o) {
        return o;
    }
};

function enumSerializer(enumObject: any): Serializer {
    return {
        serialize(o) {
            if (o === null || o === undefined)
                return o;
            else
                return enumObject[o];
        },
        deserialize(o) {
            if (o === null || o === undefined)
                return o;
            else
                return enumObject[o];
        }
    }
}

interface SerializerMap {
    [name: string]: Serializer;
}

export abstract class ApinaEndpointContext {

    constructor(protected config: ApinaConfig) {
    }

    abstract request(data: RequestData): Observable<any>

    url(data: UrlData): string {
        const url = this.buildUrl(data.uriTemplate, data.pathVariables);
        return url + formatQueryParameters(data.requestParams);
    }

    serialize(value: any, type: string): any {
        return this.config.serialize(value, type);
    }

    deserialize(value: any, type: string): any {
        return this.config.deserialize(value, type);
    }

    protected buildUrl(uriTemplate: String, pathVariables: any): string {
        return this.config.baseUrl + uriTemplate.replace(/{([^}]+)}/g, (match, name) => pathVariables[name]);
    }
}

@Injectable()
export class DefaultApinaEndpointContext extends ApinaEndpointContext {

    constructor(private httpClient: HttpClient, config: ApinaConfig) {
        super(config);
    }

    request(data: RequestData): Observable<any> {
        const url = this.buildUrl(data.uriTemplate, data.pathVariables);

        const requestParams = data.requestParams;
        let params: HttpParams | undefined = undefined;
        if (requestParams != null) {
            const filteredParams: { [key: string]: any }  = {};
            for (const key of Object.keys(requestParams)) {
                const value = requestParams[key];
                if (value != null)
                    filteredParams[key] = value;
            }

            params = new HttpParams({fromObject: filteredParams});
        }


        return this.httpClient.request(data.method, url, { params: params, body: data.requestBody })
            .pipe(map(r => data.responseType ? this.config.deserialize(r, data.responseType) : r));
    }
}

@Injectable()
export class FrontendEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    acceptUserRequest(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/request/{id}',
            'method': 'POST',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    addUserRequest(request: UserRequestModel): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/request',
            'method': 'POST',
            'requestBody': this.context.serialize(request, 'UserRequestModel')
        });
    }

    createOrganization(createOrganization: CreateOrganization): Observable<UUID> {
        return this.context.request({
            'uriTemplate': '/api/organization',
            'method': 'POST',
            'requestBody': this.context.serialize(createOrganization, 'CreateOrganization'),
            'responseType': 'UUID'
        });
    }

    createToken(): Observable<TokenModel> {
        return this.context.request({
            'uriTemplate': '/api/token',
            'method': 'POST',
            'responseType': 'TokenModel'
        });
    }

    declineUserRequest(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/request/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteToken(): Observable<boolean> {
        return this.context.request({
            'uriTemplate': '/api/token',
            'method': 'DELETE',
            'responseType': 'boolean'
        });
    }

    getAllRoles(): Observable<string[]> {
        return this.context.request({
            'uriTemplate': '/api/roles',
            'method': 'GET',
            'responseType': 'string[]'
        });
    }

    getAllUserRequests(): Observable<UserRequestWithOrganization[]> {
        return this.context.request({
            'uriTemplate': '/api/requests',
            'method': 'GET',
            'responseType': 'UserRequestWithOrganization[]'
        });
    }

    getAuthenticatedUser(): Observable<YtiUser> {
        return this.context.request({
            'uriTemplate': '/api/authenticated-user',
            'method': 'GET',
            'responseType': 'YtiUser'
        });
    }

    getConfiguration(): Observable<ConfigurationModel> {
        return this.context.request({
            'uriTemplate': '/api/config',
            'method': 'GET',
            'responseType': 'ConfigurationModel'
        });
    }

    getOrganization(id: UUID): Observable<OrganizationWithUsers> {
        return this.context.request({
            'uriTemplate': '/api/organization/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'UUID')
            },
            'responseType': 'OrganizationWithUsers'
        });
    }

    getOrganizations(): Observable<OrganizationListItem[]> {
        return this.context.request({
            'uriTemplate': '/api/organizations',
            'method': 'GET',
            'responseType': 'OrganizationListItem[]'
        });
    }

    getOrganizationsOpt(showRemoved: boolean): Observable<OrganizationListItem[]> {
        return this.context.request({
            'uriTemplate': '/api/organizations/{showRemoved}',
            'method': 'GET',
            'pathVariables': {
                'showRemoved': this.context.serialize(showRemoved, 'boolean')
            },
            'responseType': 'OrganizationListItem[]'
        });
    }

    getOrganizationsWithChildren(): Observable<OrganizationListItem[]> {
        return this.context.request({
            'uriTemplate': '/api/organizations/withChildren',
            'method': 'GET',
            'responseType': 'OrganizationListItem[]'
        });
    }

    getTestUsers(): Observable<UserWithRolesInOrganizations[]> {
        return this.context.request({
            'uriTemplate': '/api/testUsers',
            'method': 'GET',
            'responseType': 'UserWithRolesInOrganizations[]'
        });
    }

    getUsers(): Observable<UserWithRolesInOrganizations[]> {
        return this.context.request({
            'uriTemplate': '/api/users',
            'method': 'GET',
            'responseType': 'UserWithRolesInOrganizations[]'
        });
    }

    getUsersForOwnOrganizations(): Observable<UserWithRolesInOrganizations[]> {
        return this.context.request({
            'uriTemplate': '/api/usersForOwnOrganizations',
            'method': 'GET',
            'responseType': 'UserWithRolesInOrganizations[]'
        });
    }

    getVersion(): Observable<VersionInformation> {
        return this.context.request({
            'uriTemplate': '/api/version',
            'method': 'GET',
            'responseType': 'VersionInformation'
        });
    }

    removeUser(email: string): Observable<boolean> {
        return this.context.request({
            'uriTemplate': '/api/removeuser/{email}/',
            'method': 'POST',
            'pathVariables': {
                'email': this.context.serialize(email, 'string')
            },
            'responseType': 'boolean'
        });
    }

    updateOrganization(updateOrganization: UpdateOrganization): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/organization',
            'method': 'PUT',
            'requestBody': this.context.serialize(updateOrganization, 'UpdateOrganization')
        });
    }

}

@Injectable()
export class PrivateApiEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    addUserRequest(userId: UUID, organizationId: UUID, role: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/private-api/request',
            'method': 'POST',
            'requestParams': {
                'userId': this.context.serialize(userId, 'UUID'),
                'organizationId': this.context.serialize(organizationId, 'UUID'),
                'role': this.context.serialize(role, 'string')
            }
        });
    }

    createTempUsers(containerUri: string, tempUsers: TempUser[]): Observable<PublicApiUser[]> {
        return this.context.request({
            'uriTemplate': '/private-api/tempusers',
            'method': 'POST',
            'requestParams': {
                'containerUri': this.context.serialize(containerUri, 'string')
            },
            'requestBody': this.context.serialize(tempUsers, 'TempUser[]'),
            'responseType': 'PublicApiUser[]'
        });
    }

    getChildOrganizations(parentId: UUID): Observable<PublicApiOrganization[]> {
        return this.context.request({
            'uriTemplate': '/private-api/childorganizations',
            'method': 'GET',
            'requestParams': {
                'parentId': this.context.serialize(parentId, 'UUID')
            },
            'responseType': 'PublicApiOrganization[]'
        });
    }

    getParentOrganization(childOrganizationId: UUID): Observable<PublicApiOrganization> {
        return this.context.request({
            'uriTemplate': '/private-api/parentorganization',
            'method': 'GET',
            'requestParams': {
                'childOrganizationId': this.context.serialize(childOrganizationId, 'UUID')
            },
            'responseType': 'PublicApiOrganization'
        });
    }

    getTempUsers(): Observable<PrivateApiTempUserListItem[]> {
        return this.context.request({
            'uriTemplate': '/private-api/tempusers',
            'method': 'GET',
            'responseType': 'PrivateApiTempUserListItem[]'
        });
    }

    getUserByEmail(newUser: NewUser): Observable<PublicApiUser> {
        return this.context.request({
            'uriTemplate': '/private-api/user',
            'method': 'POST',
            'requestBody': this.context.serialize(newUser, 'NewUser'),
            'responseType': 'PublicApiUser'
        });
    }

    getUserRequests(userId: UUID): Observable<PublicApiUserRequest[]> {
        return this.context.request({
            'uriTemplate': '/private-api/requests',
            'method': 'GET',
            'requestParams': {
                'userId': this.context.serialize(userId, 'UUID')
            },
            'responseType': 'PublicApiUserRequest[]'
        });
    }

    getUsers(): Observable<PublicApiUserListItem[]> {
        return this.context.request({
            'uriTemplate': '/private-api/users',
            'method': 'GET',
            'responseType': 'PublicApiUserListItem[]'
        });
    }

    sendContainerTempUserEmails(containerUri: string): Observable<number> {
        return this.context.request({
            'uriTemplate': '/private-api/sendcontaineremails',
            'method': 'POST',
            'requestParams': {
                'containerUri': this.context.serialize(containerUri, 'string')
            },
            'responseType': 'number'
        });
    }

    validateUserToken(token: TokenModel): Observable<YtiUser> {
        return this.context.request({
            'uriTemplate': '/private-api/validate',
            'method': 'POST',
            'requestBody': this.context.serialize(token, 'TokenModel'),
            'responseType': 'YtiUser'
        });
    }

}

@Injectable()
export class PublicApiEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    findUserById(id: UUID): Observable<PublicApiUser> {
        return this.context.request({
            'uriTemplate': '/public-api/user',
            'method': 'GET',
            'requestParams': {
                'id': this.context.serialize(id, 'UUID')
            },
            'responseType': 'PublicApiUser'
        });
    }

    getOrganizations(onlyValid: boolean, ifModifiedSinceParam: string): Observable<PublicApiOrganization[]> {
        return this.context.request({
            'uriTemplate': '/public-api/organizations',
            'method': 'GET',
            'requestParams': {
                'onlyValid': this.context.serialize(onlyValid, 'boolean'),
                'ifModifiedSince': this.context.serialize(ifModifiedSinceParam, 'string')
            },
            'responseType': 'PublicApiOrganization[]'
        });
    }

    getUsers(): Observable<PublicApiUserListItem[]> {
        return this.context.request({
            'uriTemplate': '/public-api/users',
            'method': 'GET',
            'responseType': 'PublicApiUserListItem[]'
        });
    }

}

@Injectable()
export class SystemApiEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

}


export function apinaConfigFactory() {
    return new ApinaConfig();
}

@NgModule({
    imports: [HttpClientModule],
    providers: [
        FrontendEndpoint,
        PrivateApiEndpoint,
        PublicApiEndpoint,
        SystemApiEndpoint,
        { provide: ApinaEndpointContext, useClass: DefaultApinaEndpointContext },
        { provide: ApinaConfig, useFactory: apinaConfigFactory }
    ]
})
export class ApinaModule {}
